import { createStore } from 'vuex';

import user from './modules/user';
// import settings from './modules/settings';
import dropdown from './modules/dropdowns';
// import modals from './modules/modals';

// Create a new store instance.
const store = createStore({
  modules: {
    user, dropdown
  }
});
export default store
