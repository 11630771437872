<template>
    <svg width="92" height="36" viewBox="0 0 92 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#EFF0FF" class="overlay">
            <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0;0"
                    repeatCount="indefinite"
                    begin="0.1"/>
        </circle>
        <circle cx="18" cy="18" r="10" fill="#486EE7">
            <animate
                    attributeName="fill"
                    dur="1s"
                    values="#DBE1FF;#486EE7;#DBE1FF;#DBE1FF"
                    repeatCount="indefinite"
                    begin="0.1"/>
        </circle>

        <circle cx="46" cy="18" r="18" fill="#EFF0FF" opacity="0" class="overlay">
            <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0;0"
                    repeatCount="indefinite"
                    begin="0.4"/>
        </circle>
        <circle cx="46" cy="18" r="10" fill="#DBE1FF">
            <animate
                    attributeName="fill"
                    dur="1s"
                    values="#DBE1FF;#486EE7;#DBE1FF;#DBE1FF"
                    repeatCount="indefinite"
                    begin="0.4"/>
        </circle>

        <circle cx="74" cy="18" r="18" fill="#EFF0FF" opacity="0" class="overlay">
            <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0;0"
                    repeatCount="indefinite"
                    begin="0.7"/>
        </circle>
        <circle cx="74" cy="18" r="10" fill="#DBE1FF">
            <animate
                    attributeName="fill"
                    dur="1s"
                    values="#DBE1FF;#486EE7;#DBE1FF;#DBE1FF"
                    repeatCount="indefinite"
                    begin="0.7"/>
        </circle>
    </svg>
</template>
