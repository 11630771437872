<template>
    <div v-if="item_data">
        <p class="font-600 mb-6">Customer info</p>
        <div class="row ">
            <i-input class="col-8 mb-4" v-model="item_data.contractNumber" id="contractNumber"
                     label="Contract Number"
                     :disabled="disabled"></i-input>

            <i-input class="col-5 mb-4" v-model="item_data.firstName" id="firstName"
                     label="First name"
                     :disabled="disabled"></i-input>
            <i-input class="col-5 mb-4" v-model="item_data.lastName" id="lastName"
                     label="Last name"
                     :disabled="disabled"></i-input>

            <i-input class="col-5 mb-4" v-model="item_data.email" id="email"
                     label="Email" :error="v$.item_data.email.$error" :errorMsg="'Incorrect email'"
                     :disabled="disabled"></i-input>
            <i-input class="col-5 mb-4" v-model="item_data.customerMobileNumber" id="customerMobileNumber"
                     label="Telephone number"
                     :disabled="disabled"></i-input>

            <i-input class="col-8 mb-4" v-model="item_data.address" id="address"
                     label="Address"
                     :disabled="disabled"></i-input>

            <map-with-point v-if="map" :item="item_data"></map-with-point>

            <i-textarea class="col-8 mb-4" v-model="item_data.remarks" id="remarks"
                     label="Notes"
                     :disabled="disabled"></i-textarea>
        </div>

        <div class="mb-24" v-if="image">
            <div>
                <p class="fs-14 mb-3" v-if="pictures || (!pictures && !disabled)">Photo</p>
                <div v-if="pictures">
                    <images-slider @update="updateImages"
                                   @loading="updateLoading"
                                   :disabled="disabled"
                                   :pictures="pictures">
                    </images-slider>
                </div>
            </div>

            <label for="new_image" class="input-file" v-if="!disabled">
                <input @input="addImage" id="new_image" type="file">
                <img src="/svg/image.svg" alt="">
                <span>Add photo</span>
            </label>
        </div>

        <p class="font-600 mb-6">Technical</p>
        <div class="row mb-4">
            <i-input class="col-8 mb-4" v-model="item_data.barcode" required id="barcode"
                     label="Barcode" :error="v$.item_data.barcode.$error" :errorMsg="'This field is required'"
                     :disabled="disabled"></i-input>

            <i-input class="col-5 mb-4" v-model="item_data.standNo" id="standNo"
                     label="Stand No." :error="v$.item_data.standNo.$error" :errorMsg="'Only numbers'"
                     :disabled="disabled"></i-input>
            <i-input class="col-5 mb-4" v-model="item_data.reading" id="reading"
                     label="Meter Reading" :error="v$.item_data.reading.$error" :errorMsg="'Only numbers'"
                     :disabled="disabled"></i-input>

            <i-select class="col-5 mb-4" v-model="item_data.manufactureID" v-if="dropdown"
                      id="manufactureID" required :error="v$.item_data.manufactureID.$error" :errorMsg="'This field is required'"
                      label="Manufacturer" :disabled="disabled" :options="dropdown.Manufacture" title="Name"
                      option_key="Code"></i-select>

            <i-select class="col-5 mb-4" v-model="item_data.modelID" v-if="dropdown"
                      id="modelID" required :error="v$.item_data.modelID.$error" :errorMsg="'This field is required'"
                      label="Model" :disabled="disabled" :options="model_options" title="ModelName" :no_data_text="'Select manufacturer'"
                      option_key="ModelCode"></i-select>

            <i-select class="col-5 mb-4" v-model="item_data.pipeSize" v-if="dropdown"
                      id="pipeSize"
                      label="Pipe Size" :disabled="disabled" :options="dropdown.waterMeterPipeSize" title="Value"
                      option_key="Code"></i-select>
            <i-select class="col-5 mb-4" v-model="item_data.pipeType" v-if="dropdown"
                      id="pipeType"
                      label="Pipe Type" :disabled="disabled" :options="dropdown.waterMeterPipeType" title="Value"
                      option_key="Code"></i-select>
            <i-select class="col-5 mb-4" v-model="item_data.fittingsType" v-if="dropdown"
                      id="fittingsType"
                      label="Fittings Type" :disabled="disabled" :options="dropdown.waterMeterFittingType" title="Value"
                      option_key="Code"></i-select>
            <i-select class="col-5 mb-4" v-model="item_data.position" v-if="dropdown"
                      id="position"
                      label="Meter Position" :disabled="disabled" :options="dropdown.waterMeterPosition" title="Value"
                      option_key="Code"></i-select>
            <i-select class="col-5 mb-4" v-model="item_data.commType" v-if="dropdown"
                      id="commType"
                      label="Communication Type" :disabled="disabled" :options="dropdown.waterMeterCommType" title="Value"
                      option_key="Code"></i-select>

            <i-input class="col-5 mb-4" v-model="item_data.sizeLimitation" id="sizeLimitation"
                     label="Size limitation"
                     :disabled="disabled"></i-input>

            <i-select class="col-5 mb-4" v-model="item_data.measureTypeID" v-if="dropdown"
                      id="measureTypeID"
                      label="Measure type" :disabled="disabled" :options="dropdown.waterMeterMeasureType" title="Value"
                      option_key="Code"></i-select>
        </div>
        <div class="row">
            <div class="col-5">
                <div class="input-switch" :class="{'input-selected': item_data.valveBeforeMeter && item_data.valveBeforeMeter == 1, 'disabled': disabled}">
                    <label for="valveBeforeMeter">Valve before the meter</label>
                    <input id="valveBeforeMeter" value="1" type="checkbox" :disabled="disabled" v-model="item_data.valveBeforeMeter">
                </div>
            </div>

            <div class="col-5">
                <div class="input-switch" :class="{'input-selected': item_data.valveAfterMeter && item_data.valveAfterMeter == 1, 'disabled': disabled}">
                    <label for="valveAfterMeter">Valve after the meter</label>
                    <input id="valveAfterMeter" value="1" type="checkbox" :disabled="disabled" v-model="item_data.valveAfterMeter">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core';
    import ImagesSlider from './ImagesSlider';
    import {emailRule, reqRule, numRule} from "../../services/Validation";

    export default {
        setup () {
            return { v$: useVuelidate() }
        },
        props: {
            item: {
                default: null,
                type: Object
            },
            disabled: {
                default: true,
                type: Boolean
            },
            image: {
                default: false,
                type: Boolean
            },
            map: {
                default: false,
                type: Boolean
            }
        },
        components: {ImagesSlider},
        data() {
            return {
                item_data: {
                    contractNumber: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    customerMobileNumber: null,
                    address: null,
                    remarks: null,
                    barcode: null,
                    standNo: null,
                    reading: null,
                    manufactureID: null,
                    modelID: null,
                    pipeSize: null,
                    pipeType: null,
                    fittingsType: null,
                    position: null,
                    commType: null,
                    sizeLimitation: null,
                    measureTypeID: null,
                    valveBeforeMeter: null,
                    valveAfterMeter: null,
                },
                pictures: null
            }
        },
        computed: {
            dropdown() {
                return this.$store.getters.getDropdown;
            },
            model_options() {
                let result = [];
                if(this.item_data.manufactureID && this.item_data.manufactureID == 1) result = this.dropdown.Model.Powercom;
                if(this.item_data.manufactureID && this.item_data.manufactureID == 29) result = this.dropdown.Model.Unknown;
                return result;
            }
        },
        watch: {
            item() {
                this.setData(this.item);
            },
            // 'item_data.manufactureID'() {
            //     this.item_data.modelID = null;
            // }
        },

        mounted() {
            if(!this.dropdown) {
                this.$store.dispatch('fetchDropdown', this.axios);
            }
            if(this.item) this.setData(this.item);
        },

        methods: {
            setData(data) {
                this.item_data = data;
                this.pictures = null;
                this.getImage();
            },
            getImage() {
                if(this.image) {
                    let url = `/water/getPicture/${this.item.ID}`;
                    this.axios.get(url)
                        .then(response => {
                            if(response) {
                                this.pictures = response.data;
                                this.updateLoading(false)
                            }
                        })
                        .catch((error => {
                            if(error) {
                                this.pictures = null;
                                this.updateLoading(false)
                            }
                        }))
                }
            },
            addImage(e) {
                if(e && e.target) {
                    this.$emit('loading', true);
                    let file = e.target.files[0];
                    let url = `/water/addPicture`;
                    let formData = new FormData();
                    formData.append('assetID', this.item.ID);
                    formData.append('deviceType', 3);
                    formData.append('photoType', 1);
                    formData.append('file', file);
                    this.axios.post(url, formData)
                        .then(response => {
                            if(response) {
                                this.getImage();
                                this.showErrorMap(response.data.message);
                            }
                        })
                        .catch((error) => {
                            if (error) this.showErrorMap(error.message);
                        })
                }
            },
            showErrorMap(error) {
                this.mainNotification(error);
            },
            updateLoading(value) {
                this.$emit('loading', value);
            },
            updateImages(id) {
                let image_index = this.pictures.findIndex(image => image.ID == id);
                this.pictures.splice(image_index, 1);
                this.updateLoading(false)
            },
            validateData() {
                this.v$.$touch();
                return this.v$.$validate()
                    .then(validation => {
                        if(!validation) return false;
                        if(validation)  return true;
                    })
            }
        },

        validations() {
            return {
                item_data: {
                    standNo: numRule,
                    reading: numRule,
                    email: emailRule,
                    barcode: reqRule,
                    manufactureID: reqRule,
                    modelID: reqRule,
                }
            }
        }
    }
</script>
