<template>
    <div id="map">
        <div class="google-map" ref="googleMap"></div>
        <div class="loading" v-if="loading"></div>


        <!-- contextmenu for new asset -->
        <div v-if="add_new_marker_contextmenu.visible"
             :style="{left: add_new_marker_contextmenu.left, top: add_new_marker_contextmenu.top}"
             class="contextmenu">
            <ul>
                <li @click="selectCreation()">Add new meter</li>
            </ul>
        </div>


        <div class="controls zoom-control" :style="zoomStyle">
            <button class="zoom-control-in" title="Zoom In" :class="{'disabled': mapConfig.scale == 22}"
                    :disabled="mapConfig.scale == 22">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H4V4H0V6H4V10H6V6H10V4H6V0Z" fill="#45464E"/>
                </svg>
            </button>
            <button class="zoom-control-out" :class="{'disabled': mapConfig.scale == 3}"
                    :disabled="mapConfig.scale == 3" title="Zoom Out">
                <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2V0H10V2H0Z" fill="#45464E"/>
                </svg>
            </button>
        </div>

        <div class="maptype-control maptype-control-is-map">
            <button class="maptype-control-map maptype-control" title="Show road map">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.49 19.0048L5.12 13.2748L3.5 14.5348L12.5 21.5348L21.5 14.5348L19.87 13.2648L12.49 19.0048ZM12.5 16.4648L19.86 10.7348L21.5 9.46484L12.5 2.46484L3.5 9.46484L5.13 10.7348L12.5 16.4648ZM12.5 4.99484L18.24 9.46484L12.5 13.9348L6.76 9.46484L12.5 4.99484Z"
                          fill="#000000"/>
                </svg>
                <span>Map</span>
            </button>
            <button class="maptype-control-satellite maptype-control" title="Show satellite imagery">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.49 19.0048L5.12 13.2748L3.5 14.5348L12.5 21.5348L21.5 14.5348L19.87 13.2648L12.49 19.0048ZM12.5 16.4648L19.86 10.7348L21.5 9.46484L12.5 2.46484L3.5 9.46484L5.13 10.7348L12.5 16.4648ZM12.5 4.99484L18.24 9.46484L12.5 13.9348L6.76 9.46484L12.5 4.99484Z"
                          fill="white"/>
                </svg>
                <span>Layers</span>
            </button>
        </div>

        <create-asset ref="createAsset"
                      :marker="new_marker.marker"
                      :add_new_marker="add_new_marker"
                      @set_step="setCreationStep"
                      @confirm_asset_position="confirmAssetPosition"
                      @hide_creation="hideCreation">
        </create-asset>
    </div>
</template>

<script>
    import {toRaw} from "vue";
    import mapStyle from '../../assets/json/map_style';
    import {Loader} from '@googlemaps/js-api-loader';
    import CreateAsset from './../asset/CreateAsset'

    export default {
        props: {
            mapConfig: Object,
            mapData: Object,
            activeAsset: {
                default: null
            },
            activeItem: {
                default: null
            }
        },
        components: {
            CreateAsset
        },
        data() {
            return {
                loading: false,

                google: null,
                map: null,

                markers: null,
                clusters: null,

                /*active cluster*/
                selectCluster: null,
                selectClusterIndex: null,

                /*active asset*/
                selectMarker: null,
                selectMarkerIndex: null,

                /*new asset data*/
                add_new_marker: false,
                new_marker: {
                    step: 0,
                    asset: null,
                    marker: null,
                },
                add_new_marker_contextmenu: {
                    left: 0,
                    top: 0,
                    visible: false
                },
            }
        },

        computed: {
            zoomStyle() {
                let result;
                // if (this.activeItem) result = {'right': '357px'};
                // if (!this.activeItem)
                result = {'right': '16px'};
                return result;
            },
            user() {
                return this.$store.getters.getUser;
            }
        },

        watch: {
            mapData() {
                this.loadMapData();
            },
        },

        mounted() {
            const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
            const loaderMap = new Loader({
                apiKey: key,
                version: "weekly",
            });
            this.initializeMap(loaderMap);
        },

        methods: {
            initializeMap(loader) {
                const mapContainer = this.$refs.googleMap;
                let center;
                let map;
                let self = this;

                loader
                    .load()
                    .then((google) => {
                        this.google = google;

                        let address = this.user.city && this.user.country ? this.user.city + ' ' + this.user.country : null;
                        let geocoder = new google.maps.Geocoder();

                        geocoder.geocode({
                            'address': address
                        }, function (results, status) {
                            if (status == google.maps.GeocoderStatus.OK && address) {
                                let Lat = results[0].geometry.location.lat();
                                let Lng = results[0].geometry.location.lng();
                                center = new google.maps.LatLng(Lat, Lng);
                            } else {
                                center = new google.maps.LatLng(31.904991, 34.993903)
                            }

                            //TODO ВРЕМЕННО
                            // center = new google.maps.LatLng(31.904991, 34.993903);

                            map = new google.maps.Map(mapContainer, {
                                mapTypeIds: 'styled_map',
                                streetViewControl: false,
                                zoom: 10,
                                minZoom: 3,
                                maxZoom: 22,
                                center: center,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                                styles: mapStyle
                            });

                            self.map = map;

                            self.initZoomControl(self.map);
                            self.initMapTypeControl(self.map);

                            self.map.addListener('idle', () => {
                                let bounds = self.map.getBounds();
                                let southWest = bounds.getSouthWest();
                                let northEast = bounds.getNorthEast();
                                self.reset(southWest, northEast, self.map.zoom);
                                self.$emit('map_dragend', southWest, northEast, self.map.zoom);
                                // if (self.selectMarker) self.setActiveMarker(self.selectMarker);
                            });

                            /* left click */
                            self.map.addListener('click', (e) => {
                                self.addNewMarker(e);
                            });

                            /* Right click */
                            self.map.addListener('contextmenu', () => {

                            });

                            self.markers = [];
                            self.clusters = [];
                            self.loadMapData();
                        });
                    });
            },

            /*INIT BUTTON FOR ZOOM MAP*/
            initZoomControl(map) {
                document.querySelector(".zoom-control-in").onclick = function () {
                    map.setZoom(map.getZoom() + 1);
                };

                document.querySelector(".zoom-control-out").onclick = function () {
                    map.setZoom(map.getZoom() - 1);
                };
            },
            /*INIT BUTTON FOR CHANGE TYPE MAP*/
            initMapTypeControl(map) {
                const mapTypeControlDiv = document.querySelector(".maptype-control");

                document.querySelector(".maptype-control-map").onclick = function () {
                    mapTypeControlDiv.classList.add("maptype-control-is-map");
                    mapTypeControlDiv.classList.remove("maptype-control-is-satellite");
                    map.setMapTypeId("roadmap");
                };

                document.querySelector(".maptype-control-satellite").onclick = function () {
                    mapTypeControlDiv.classList.remove("maptype-control-is-map");
                    mapTypeControlDiv.classList.add("maptype-control-is-satellite");
                    map.setMapTypeId("satellite");
                };

            },

            /*LOAD MARKERS*/
            loadMapData() {
                if (!this.mapData.length) {
                    this.loading = false;
                    return;
                }
                this.getMarkers();
            },

            /*GENERATE A MAP MARKER*/
            getMarkers() {
                this.mapData.forEach((marker, index) => {
                    /* CLUSTERS */
                    if (marker.deviceType == 11) {
                        if (this.activeItem) {
                            this.$emit('close_item_dialog');
                        }
                        /*set marker*/
                        this.addClusterMarker(marker, index);
                    }

                    /* FINAL DEVICES */
                    if (marker.deviceType == 212) {
                        /*set marker*/
                        this.addMarker(marker, index);
                    }
                });
            },

            /* CLUSTER FUNCTIONS --------------------------------------------------------- START --- */
            addClusterMarker(marker, index) {
                let icon = {
                    anchor: null,
                    url: null,
                };

                if (!this.selectCluster || this.selectCluster && this.selectCluster.deviceId != marker.deviceid) icon = this.setDefaultClusterIcon(marker);
                if (this.selectCluster && this.selectCluster.deviceId == marker.deviceid) {
                    icon = this.setActiveClusterIcon(marker);
                    this.selectClusterIndex = index;
                }

                const mapCluster = new this.google.maps.Marker({
                    position: new this.google.maps.LatLng(
                        marker.latitude,
                        marker.longitude
                    ),
                    label: {
                        text: String(marker.numOfDevices),
                        color: '#001452',
                        fontFamily: '"IBM Plex Sans", sans-serif',
                        fontSize: this.setFontSizeTitle(marker.numOfDevices),
                        fontWeight: 'bold',
                    },
                    lat: marker.latitude,
                    lng: marker.longitude,
                    map: this.map,
                    title: marker.name,
                    animation: this.google.maps.Animation.DROP,
                    deviceType: marker.deviceType,
                    deviceId: marker.deviceid,
                    numOfDevices: marker.numOfDevices,
                    zIndex: 1,
                    icon: {
                        anchor: icon.anchor,
                        url: icon.url,
                    },
                    optimized: true
                });
                toRaw(mapCluster).setMap(this.map);

                /* cluster set hover listener */
                mapCluster.addListener('mouseover', () => {
                    if (!this.selectCluster || (this.selectCluster && mapCluster.deviceId != this.selectCluster.deviceId)) this.setHoveredCluster(mapCluster);
                });
                /* cluster remove hover listener */
                mapCluster.addListener('mouseout', () => {
                    if (!this.selectCluster || (this.selectCluster && mapCluster.deviceId != this.selectCluster.deviceId)) this.removeHoveredCluster(mapCluster);
                });
                /* cluster click listener */
                mapCluster.addListener('click', () => {
                    if (!this.selectCluster || (this.selectCluster && mapCluster.deviceId != this.selectCluster.deviceId)) this.setActiveCluster(mapCluster)
                });

                this.clusters.push(mapCluster);
            },

            setFontSizeTitle(num) {
                let result = '24px';
                if (num > 99999) result = '18px';
                if (num > 9999 && num < 100000) result = '20px';
                return result
            },

            setActiveCluster(cluster) {
                if (!this.add_new_marker && this.mapConfig.scale > 9) {
                    if (cluster && cluster.title) {
                        this.loading = true;
                        this.$emit('set_active_cluster', cluster.deviceId);
                        this.selectCluster = cluster;

                        let cluster_index = this.clusters.findIndex(item => item.deviceId == cluster.deviceId);
                        toRaw(this.clusters[cluster_index]).setIcon(this.setActiveClusterIcon(cluster));
                        toRaw(this.clusters[cluster_index]).setZIndex(9);

                        if (this.selectClusterIndex || this.selectClusterIndex == '0') {
                            let icon = this.setDefaultClusterIcon(this.clusters[this.selectClusterIndex]);
                            toRaw(this.clusters[this.selectClusterIndex]).setIcon(icon);
                            toRaw(this.clusters[this.selectClusterIndex]).setZIndex(1);
                        }
                        this.selectClusterIndex = cluster_index;
                    }
                }
            },

            setHoveredCluster(cluster) {
                let cluster_index = this.clusters.findIndex(item => item.deviceId == cluster.deviceId);
                toRaw(this.clusters[cluster_index]).setIcon(this.setHoverClusterIcon(cluster));
                toRaw(this.clusters[cluster_index]).setZIndex(10);
            },
            removeHoveredCluster(cluster) {
                let cluster_index = this.clusters.findIndex(item => item.deviceId == cluster.deviceId);
                toRaw(this.clusters[cluster_index]).setIcon(this.setDefaultClusterIcon(cluster));
                toRaw(this.clusters[cluster_index]).setZIndex(1);
            },

            /* set active icon --- CLUSTER */
            setActiveClusterIcon(cluster) {
                if (cluster) {
                    return {
                        anchor: new this.google.maps.Point(43, 43),
                        url: `./../../../svg/cluster_active.svg`,
                    }
                }
            },
            /* set hovered icon --- CLUSTER */
            setHoverClusterIcon(cluster) {
                if (cluster) {
                    return {
                        anchor: new this.google.maps.Point(38, 38),
                        url: `./../../../svg/cluster_primary_hover.svg`,
                    }
                }
            },
            /* set default icon --- CLUSTER */
            setDefaultClusterIcon(cluster) {
                if (cluster) {
                    return {
                        anchor: new this.google.maps.Point(38, 38),
                        url: `./../../../svg/cluster_primary.svg`,
                    }
                }
            },
            /* CLUSTER FUNCTIONS ------------------------------------------------------- END --- */

            /* MARKER FUNCTIONS ------------------------------------------------------- START --- */
            addMarker(marker, index) {
                let icon = {
                    anchor: null,
                    url: null,
                };

                if (!this.selectMarker || this.selectMarker && this.selectMarker.deviceId != marker.deviceid) icon = this.setDefaultIcon(marker);
                if (this.selectMarker && this.selectMarker.deviceId == marker.deviceid) {
                    icon = this.setActiveIcon(marker);
                    this.selectMarkerIndex = index;
                }

                const new_marker = new this.google.maps.Marker({
                    position: new this.google.maps.LatLng(
                        marker.latitude,
                        marker.longitude
                    ),
                    lat: marker.latitude,
                    lng: marker.longitude,
                    title: marker.name ? marker.name : String(marker.deviceid),
                    deviceId: marker.deviceid,
                    deviceType: marker.deviceType,
                    zIndex: 1,
                    icon: {
                        anchor: icon.anchor,
                        url: icon.url,
                    },
                    optimized: true
                });
                toRaw(new_marker).setMap(this.map);

                /* marker set hover listener */
                new_marker.addListener('mouseover', () => {
                    if (!this.selectMarker || (this.selectMarker && new_marker.deviceId != this.selectMarker.deviceId)) this.setHoveredMarker(new_marker);
                });
                /* marker remove hover listener */
                new_marker.addListener('mouseout', () => {
                    if (!this.selectMarker || (this.selectMarker && new_marker.deviceId != this.selectMarker.deviceId)) this.removeHoveredMarker(new_marker);
                });
                /* marker click listener */
                new_marker.addListener('click', () => {
                    if (!this.selectMarker || (this.selectMarker && new_marker.deviceId != this.selectMarker.deviceId)) this.setActiveMarker(new_marker)
                });

                this.markers.push(new_marker);
            },

            /*set active asset after click*/
            setActiveMarker(marker) {
                if (!this.add_new_marker) {
                    if (marker && marker.title) {
                        this.loading = true;
                        this.$emit('set_active_marker', marker.deviceId, 'map');
                        this.selectMarker = marker;

                        let marker_index = this.markers.findIndex(item => item.deviceId == marker.deviceId);
                        toRaw(this.markers[marker_index]).setIcon(this.setActiveIcon(marker));
                        toRaw(this.markers[marker_index]).setZIndex(9);

                        if (this.selectMarkerIndex || this.selectMarkerIndex == '0') {
                            let icon = this.setDefaultIcon(this.markers[this.selectMarkerIndex]);
                            toRaw(this.markers[this.selectMarkerIndex]).setIcon(icon);
                            toRaw(this.markers[this.selectMarkerIndex]).setZIndex(1);
                        }
                        this.selectMarkerIndex = marker_index;

                        let center = new this.google.maps.LatLng(this.selectMarker.lat, this.selectMarker.lng);
                        this.map.setCenter(center, this.mapConfig.scale)
                    }
                }
            },

            /*set hover asset after mouseover*/
            setHoveredMarker(marker) {
                if (marker) {
                    let marker_index = this.markers.findIndex(item => item.deviceId == marker.deviceId);
                    if(marker_index > -1) toRaw(this.markers[marker_index]).setIcon(this.setHoverIcon(marker));
                    if(marker_index > -1) toRaw(this.markers[marker_index]).setZIndex(10);
                }
            },
            removeHoveredMarker(marker) {
                if (marker) {
                    let marker_index = this.markers.findIndex(item => item.deviceId == marker.deviceId);
                    if(marker_index > -1) toRaw(this.markers[marker_index]).setIcon(this.setDefaultIcon(marker));
                    if(marker_index > -1) toRaw(this.markers[marker_index]).setZIndex(1);
                }
            },

            /* set default ICON*/
            setDefaultIcon(marker) {
                if (marker) {
                    return {
                        anchor: new this.google.maps.Point(16, 16),
                        url: `./../../../img/assets/${marker.deviceType}_default.png`,
                    }
                }

            },
            /* set active (clicked) ICON*/
            setActiveIcon(marker) {
                if (marker) {
                    return {
                        anchor: new this.google.maps.Point(24, 24),
                        url: `./../../../img/assets/${marker.deviceType}_selected.png`,
                    }
                }
            },
            /* set hover ICON*/
            setHoverIcon(marker) {
                if (marker) {
                    return {
                        anchor: new this.google.maps.Point(16, 16),
                        url: `./../../../img/assets/${marker.deviceType}_hover.png`,
                    }
                }
            },
            /* MARKER FUNCTIONS ---------------------------------------------------- END --- */

            /* ADD new asset ------------------------------------------------------- START ---*/
            addNewMarker(e) {
                if (!this.new_marker.marker && this.new_marker.step == 0 && e && e.pixel && e.pixel.x && !this.selectCluster && !this.selectMarker) {
                    this.new_marker.marker = new this.google.maps.Marker({
                        position: e.latLng,
                        map: this.map,
                        zIndex: 12,
                        icon: {
                            anchor: new this.google.maps.Point(24, 24),
                            url: `./../../../svg/new_asset.svg`,
                        }
                    });
                    this.add_new_marker_contextmenu.left = e.pixel.x + 14 + 'px';
                    this.add_new_marker_contextmenu.top = e.pixel.y + 80 + 'px';
                    this.add_new_marker_contextmenu.visible = true;
                    this.new_marker.marker.setMap(this.map);
                    toRaw(this.new_marker.marker).setDraggable(true);
                    this.$emit('close_all_cards', true);
                    return;
                }
                if (this.new_marker.marker && this.new_marker.step == 0) {
                    toRaw(this.new_marker.marker).setMap(null);
                    this.add_new_marker_contextmenu.visible = false;
                    this.new_marker.marker = null;
                }
            },
            setCreationStep(step) {
                this.new_marker.step = step
            },
            selectCreation() {
                this.add_new_marker_contextmenu.visible = false;
                this.add_new_marker = true;

                this.$refs.createAsset.setStep(1);
            },
            confirmAssetPosition() {
                toRaw(this.new_marker.marker).setDraggable(false);
            },
            hideCreation(marker) {
                toRaw(this.new_marker.marker).setMap(null);
                if(marker) this.addMarker(marker, this.markers.length);
                this.add_new_marker = false;
                this.new_marker = {
                    step: 0,
                    asset: null,
                    marker: null,
                };
                this.add_new_marker_contextmenu = {
                    left: 0,
                    top: 0,
                    visible: false
                };
                if(marker) this.mainNotification('New meter has been created successfully');

            },
            /* ADD new asset ------------------------------------------------------- END ---*/

            /*RESET active marker*/
            resetActiveMarker() {
                if (this.selectMarker) {
                    let index = this.markers.findIndex(item => item.deviceId == this.selectMarker.deviceId);
                    if (index > -1) {
                        let icon = this.setDefaultIcon(this.markers[this.selectMarkerIndex]);
                        toRaw(this.markers[index]).setIcon(icon);
                        toRaw(this.markers[index]).setZIndex(1);
                        this.selectMarker = null;
                        this.selectMarkerIndex = null;
                    } else {
                        this.selectMarker = null;
                        this.selectMarkerIndex = null;
                    }
                }
            },
            /*RESET active cluster*/
            resetActiveCluster() {
                if (this.selectCluster) {
                    let index = this.clusters.findIndex(item => item.deviceId == this.selectCluster.deviceId);
                    if (index && index > -1) {
                        let icon = this.setDefaultClusterIcon(this.clusters[this.selectClusterIndex]);
                        toRaw(this.clusters[index]).setIcon(icon);
                        toRaw(this.clusters[index]).setZIndex(1);
                        this.selectCluster = null;
                        this.selectClusterIndex = null;
                    } else {
                        this.selectCluster = null;
                        this.selectClusterIndex = null;
                    }
                }
            },
            /*RESET ALL DATA FROM MAP*/
            reset() {
                this.selectMarkerIndex = null;
                this.markers.map((marker) => toRaw(marker).setMap(null));
                this.clusters.map((cluster) => toRaw(cluster).setMap(null));
                this.markers = [];
                this.clusters = [];
            },
        }
    }
</script>

<style scoped lang="sass">
    #map
        width: 100%
        height: 100%
        position: relative

        .google-map
            width: 100%
            height: 100%

        .contextmenu
            position: fixed
            left: 0
            top: 0
            z-index: 20
            background: var(--background-light)
            padding: 8px 0
            border-radius: 8px
            width: 192px

            li
                list-style: none
                padding: 12px 16px
                font-size: 16px
                transition: 0.3s ease-in-out

                &:hover
                    cursor: pointer
                    background: var(--color-secondary-hover)

        .loading
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background: rgba(255, 255, 255, 0.2)
            z-index: 4

        .controls
            position: fixed
            background: #FFFFFF
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2)
            border-radius: 8px
            z-index: 2

        .zoom-control
            width: 40px
            right: 16px
            bottom: 66px

            .zoom-control-in, .zoom-control-out
                width: 40px
                height: 40px
                display: flex
                align-items: center
                justify-content: center
                background: #FFFFFF
                border: none

                &:hover
                    path
                        fill: var(--color-primary)

            .zoom-control-in.disabled, .zoom-control-out.disabled
                background: var(--background-disabled)

                path
                    fill: var(--text-color-gray-dark)

                &:hover
                    cursor: default

            .zoom-control-in
                position: relative
                margin-bottom: 1px
                border-radius: 8px 8px 0 0

                &::before
                    content: ''
                    position: absolute
                    left: 4px
                    bottom: -1px
                    width: 32px
                    height: 1px
                    background: #E2E1EC
                    z-index: 2

            .zoom-control-out
                border-radius: 0 0 8px 8px

        .maptype-control
            z-index: 2
            position: fixed
            left: 16px
            bottom: 16px
            width: 96px
            height: 96px
            border-radius: 4px
            overflow: hidden
            border: 2px solid var(--text-color-white)

            .maptype-control
                width: 96px
                height: 96px
                padding-bottom: 12px
                border-radius: 4px
                display: flex
                align-items: flex-end
                justify-content: center

                span
                    display: inline-block
                    line-height: 24px
                    font-weight: 500
                    font-size: 11px

            .maptype-control-map
                background-image: url("/public/img/map/map-dafault.jpg")

            .maptype-control-satellite
                background-image: url("/public/img/map/map-satellite.jpg")

                span
                    color: var(--text-color-white)

        .maptype-control.maptype-control-is-map
            .maptype-control-map
                display: none

        .maptype-control.maptype-control-is-satellite
            .maptype-control-satellite
                display: none


</style>
