<template>
    <div class="dialog-side left medium" :style="position" id="dialog-details">
            <div class="dialog-card">
                <div class="dialog-header" ref="header">
                    <div class="dialog-header-actions">
                        <div class="card-drag" id="drag-details">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                      fill="#909194"/>
                            </svg>
                        </div>
                    </div>

                    <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
                </div>

                <div class="dialog-body" :class="{'has-footer': edit}">
                    <div class="dialog-title mb-12">
                        <span class="fs-28">Details</span>
                        <i-button v-if="!edit" @click="edit = true"
                                  img="/svg/edit.svg"
                                  color="secondary"
                                  small
                                  :title="'Edit'">
                        </i-button>
                    </div>

                    <div class="dialog-content dialog-content-scroll">
                        <details-form ref="form" :item="item" :disabled="!edit" image @loading="setLoading"></details-form>

                        <div v-if="edit" style="padding-top: 40px">
                            <i-button @click="removeData()" img="/svg/remove.svg" color="remove" small :title="'Delete item'"></i-button>
                        </div>
                    </div>


                    <div class="dialog-footer" v-if="edit">
                        <div>
                            <div v-if="form_error" class="error-color fs-12">The information is not saved. Plese check the data.</div>
                        </div>
                        <div class="dialog-footer--items">
                            <i-button @click="cancelData()" color="secondary" small :title="'Cancel'"></i-button>
                            <i-button @click="saveData()" color="primary" small :title="'Save changes'"></i-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="loading" v-if="loading"><i-loading></i-loading></div>
        </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            item: {
                default: null,
                type: Object
            },
            position: {
                default: {
                    left: 0,
                    right: 'auto'
                }
            },
            active_image: {
                default: null
            }
        },
        data() {
            return {
                loading: false,

                item_default: {},
                edit: false,

                form_error: false,
            }
        },

        watch: {
            item() {
                for (let i in this.item) {
                    this.item_default[i] = this.item[i];
                }
                this.edit = false;
            }
        },

        mounted() {
            for (let i in this.item) {
                this.item_default[i] = this.item[i];
            }

            Draggable.create("#dialog-details", {
                trigger: "#drag-details",
                bounds: ".main-page",
                edgeResistance: 0.65,
                inertia: true,
                autoScroll: 1,
            });

        },

        methods: {
            cancelData() {
                this.form_error = false;
                this.$refs.form.setData(this.item_default);
                this.edit = false;
            },
            saveData() {
                this.form_error = false;
                this.$refs.form.validateData()
                    .then(response => {
                        if(response) {
                            this.setLoading(true);
                            let data = {
                                ID: this.$refs.form.item_data.ID,
                                address: this.$refs.form.item_data.address,
                                assetName: this.$refs.form.item_data.assetName,
                                barcode: this.$refs.form.item_data.barcode,
                                commType: Number(this.$refs.form.item_data.commType),
                                contractNumber: this.$refs.form.item_data.contractNumber,
                                customerMobileNumber: this.$refs.form.item_data.customerMobileNumber,
                                email: this.$refs.form.item_data.email,
                                firstName: this.$refs.form.item_data.firstName,
                                fittingsType: Number(this.$refs.form.item_data.fittingsType),
                                lastName: this.$refs.form.item_data.lastName,
                                latitude: this.$refs.form.item_data.latitude,
                                longitude: this.$refs.form.item_data.longitude,
                                manufactureID: Number(this.$refs.form.item_data.manufactureID),
                                measureTypeID: Number(this.$refs.form.item_data.measureTypeID),
                                modelID: Number(this.$refs.form.item_data.modelID),
                                pipeSize: Number(this.$refs.form.item_data.pipeSize),
                                pipeType: Number(this.$refs.form.item_data.pipeType),
                                position: Number(this.$refs.form.item_data.position),
                                reading: Number(this.$refs.form.item_data.reading),
                                remarks: this.$refs.form.item_data.remarks,
                                sizeLimitation: Number(this.$refs.form.item_data.sizeLimitation),
                                standNo: this.$refs.form.item_data.standNo,
                                stopValve: this.$refs.form.item_data.stopValve,
                                valveAfterMeter: this.$refs.form.item_data.valveAfterMeter,
                                valveBeforeMeter: this.$refs.form.item_data.valveBeforeMeter
                            };
                            let url = '/water/servicePoint/update';
                            this.axios.put(url, data)
                                .then(response_data => {
                                    if(response_data) {
                                        for (let i in data) {
                                            this.item_default[i] = data[i];
                                        }
                                        this.edit = false;
                                        this.setLoading(false);
                                        this.$emit('update_device', data);
                                    }
                                })
                                .catch((error) => {
                                    if(error && error.response) {
                                        this.showErrorMap(error.response.data.message[0]);
                                        this.cancelData();
                                    }
                                })
                        }
                        else this.form_error = true;
                    })

            },

            showErrorMap(error) {
                this.mainNotification(error);
            },

            removeData() {
                let text = 'Are you sure you want to delete the following element and all related data? You cannot undo this action.';
                this.$swal.fire({
                    icon: false,
                    title: 'Delete element',
                    html: `<div class="mb-5">${text}</div>
                    <div class="warning-line">
                    <img src="/svg/assets/212_single.svg" alt=""><span> Meter ${this.item.barcode}</span>
                    </div>`,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete',
                    customClass: {
                        cancelButton: 'remove',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setLoading(true);
                        let url = `/water/servicePoint/remove/${this.item.ID}`;
                        this.axios.delete(url)
                            .then(response => {
                                if(response) {
                                    this.$emit('remove_device');
                                    this.closeDialog(true);
                                }
                            })

                    }
                })
            },

            setLoading(value) {
                this.loading = value;
            },
            closeDialog(close) {
                if(!this.edit || close) this.$emit('close');
                else {
                    this.$swal.fire({
                        icon: false,
                        title: 'Discard changes?',
                        html: 'If you leave now, all entered data will not be saved.',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, leave',
                        customClass: {
                            cancelButton: 'remove',
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$emit('close');
                        }
                    })
                }
            },
        }
    }
</script>
