<template>
    <div v-if="add_new_marker">
        <div class="stepper" v-if="step">
            <div class="stepper-back" @click="hideCreation()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 8.25H5.8725L10.065 4.0575L9 3L3 9L9 15L10.0575 13.9425L5.8725 9.75H15V8.25Z"
                          fill="#2A54CD"/>
                </svg>
                <span>Grid Mapping</span>
            </div>
            <div class="stepper-navigation">
                <div class="stepper-navigation-item confirmed">
                    <div class="icon">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5001 1.6665C5.90008 1.6665 2.16675 5.39984 2.16675 9.99984C2.16675 14.5998 5.90008 18.3332 10.5001 18.3332C15.1001 18.3332 18.8334 14.5998 18.8334 9.99984C18.8334 5.39984 15.1001 1.6665 10.5001 1.6665ZM8.83342 14.1665L4.66675 9.99984L5.84175 8.82484L8.83342 11.8082L15.1584 5.48317L16.3334 6.6665L8.83342 14.1665Z" fill="#FDFCFF"/>
                        </svg>
                    </div>
                    <p>Select Position</p>
                </div>
                <div class="stepper-navigation-item" :class="{'confirmed': step > 1}">
                    <div class="icon" v-if="step == 2">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5001 1.6665C5.90008 1.6665 2.16675 5.39984 2.16675 9.99984C2.16675 14.5998 5.90008 18.3332 10.5001 18.3332C15.1001 18.3332 18.8334 14.5998 18.8334 9.99984C18.8334 5.39984 15.1001 1.6665 10.5001 1.6665ZM8.83342 14.1665L4.66675 9.99984L5.84175 8.82484L8.83342 11.8082L15.1584 5.48317L16.3334 6.6665L8.83342 14.1665Z" fill="#FDFCFF"/>
                        </svg>
                    </div>
                    <span class="num" v-else>
                       <span>2</span>
                    </span>
                    <p>Element Details</p>
                </div>
            </div>
            <div></div>
        </div>

        <!-- ***  STEP 1  *** -->
        <div v-if="step == 1" class="position_card">
            <div class="fs-28 mb-4">Confirm position</div>
            <p class="mb-4 fs-14">Drag an icon to the desired location</p>
            <div class="button-container">
                <i-button small :title="'Confirm'" @click="confirmPosition()"></i-button>
            </div>
        </div>

        <!-- ***  STEP 2  *** -->
        <div v-if="step == 2" class="dialog medium">
            <div class="dialog-card" id="dialog-create">
                <div class="dialog-header" ref="header">
                    <div>
                        <div class="card-drag" id="drag-create">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                      fill="#909194"/>
                            </svg>
                        </div>
                    </div>
                    <i-button @click="hideCreation()" icon img="/svg/close.svg"></i-button>
                </div>
                <div class="dialog-body">
                    <div class="dialog-title mb-9">
                        <span class="fs-28">Details</span>
                    </div>

                    <div class="dialog-content dialog-content-scroll">
                        <details-form ref="form" :disabled="false"></details-form>
                    </div>


                    <div class="dialog-footer">
                        <div>
                            <div v-if="form_error" class="error-color fs-12">The information is not saved. Plese check the data.</div>
                        </div>
                        <div class="dialog-footer--items">
                            <i-button @click="hideCreation()" color="secondary" small :title="'Cancel'"></i-button>
                            <i-button @click="saveData()" color="primary" small :title="'Confirm'"></i-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="loading" v-if="loading"><i-loading></i-loading></div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            add_new_marker: {
                type: Boolean,
                default: false
            },
            marker: {
                type: Object
            }
        },
        data() {
            return {
                loading: false,
                step: 0,

                form_error: false
            }
        },

        methods: {
            setStep(step) {
                this.step = step;
                this.$emit('set_step', step)
            },
            confirmPosition() {
                this.setStep(2);
                this.$emit('confirm_asset_position', true);

                this.$nextTick(() => {
                    Draggable.create("#dialog-create", {
                        trigger: "#drag-create",
                        bounds: ".main-page",
                        edgeResistance: 0.65,
                        inertia: true,
                        autoScroll: 1,
                    });
                });
            },
            hideCreation() {
                this.$swal.fire({
                    icon: false,
                    title: 'Discard changes?',
                    html: 'If you leave now, all entered data will not be saved.',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, discard',
                    customClass: {
                        cancelButton: 'remove',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.step = 0;
                        this.$emit('hide_creation');
                    }
                })

            },
            saveData() {
                this.form_error = false;
                this.$refs.form.validateData()
                    .then(response => {
                        if(response) {
                            this.loading = true;
                            let data = {
                                ID: this.$refs.form.item_data.ID,
                                address: this.$refs.form.item_data.address,
                                assetName: this.$refs.form.item_data.assetName,
                                barcode: this.$refs.form.item_data.barcode,
                                commType: Number(this.$refs.form.item_data.commType),
                                contractNumber: this.$refs.form.item_data.contractNumber,
                                customerMobileNumber: this.$refs.form.item_data.customerMobileNumber,
                                firstName: this.$refs.form.item_data.firstName,
                                fittingsType: Number(this.$refs.form.item_data.fittingsType),
                                lastName: this.$refs.form.item_data.lastName,
                                latitude: Number(this.marker.position.lat()),
                                longitude: Number(this.marker.position.lng()),
                                manufactureID: Number(this.$refs.form.item_data.manufactureID),
                                measureTypeID: Number(this.$refs.form.item_data.measureTypeID),
                                modelID: Number(this.$refs.form.item_data.modelID),
                                pipeSize: Number(this.$refs.form.item_data.pipeSize),
                                pipeType: Number(this.$refs.form.item_data.pipeType),
                                position: Number(this.$refs.form.item_data.position),
                                reading: Number(this.$refs.form.item_data.reading),
                                remarks: this.$refs.form.item_data.remarks,
                                sizeLimitation: Number(this.$refs.form.item_data.sizeLimitation),
                                standNo: this.$refs.form.item_data.standNo,
                                stopValve: this.$refs.form.item_data.stopValve,
                                valveAfterMeter: this.$refs.form.item_data.valveAfterMeter,
                                valveBeforeMeter: this.$refs.form.item_data.valveBeforeMeter
                            };
                            if(this.$refs.form.item_data.email) data.email = this.$refs.form.item_data.email;
                            else data.email = null;

                            let url = '/water/servicePoint/add';
                            this.axios.post(url, data)
                                .then(response_data => {
                                    if(response_data) {
                                        this.loading = false;
                                        this.step = 0;
                                        data.deviceType = 212;
                                        data.deviceid = response_data.data.id;
                                        this.$emit('hide_creation', data);
                                    }
                                })
                                .catch((error) => {
                                    if(error) {
                                        this.mainNotification(error.response.data.message[0]);
                                        this.loading = false;
                                    }
                                })
                        }
                        else this.form_error = true;
                    })
            }
        }
    }
</script>

<style scoped lang="sass">
    .position_card
        min-width: 286px
        max-width: 300px
        position: fixed
        z-index: 10
        left: 16px
        top: 50%
        transform: translateY(-50%)
        padding: 24px 24px 16px 24px
        background: var(--background-light)
        border-radius: 12px
        box-shadow: var(--shadow-300)

        .button-container
            text-align: right

    .dialog.medium
        .dialog-card
            min-height: 620px
            height: 80vh
            padding: 16px 0 16px 16px
            .dialog-body
                padding-right: 0

    .stepper
        z-index: 29
        position: fixed
        left: 0
        top: 0
        right: 0
        background: var(--background-light)
        height: 70px
        padding: 0 16px
        display: grid
        grid-template-columns: 160px auto 160px
        justify-content: space-between
        align-items: center
        box-shadow: 0 0.333333px 1.5px rgba(0, 0, 0, 0.039), 0 1.25px 5px rgba(0, 0, 0, 0.19)

        .stepper-back
            font-weight: 500
            color: var(--color-primary)
            display: flex
            align-items: center

            svg
                transition: 0.3s ease-in-out
                margin-right: 8px

            &:hover
                cursor: pointer

                svg
                    transform: translateX(-10px)


        .stepper-navigation
            display: grid
            grid-template-columns: repeat(2, 200px)
            border-radius: 8px
            overflow: hidden

            .stepper-navigation-item
                height: 32px
                display: flex
                align-items: center
                justify-content: center
                background: var(--color-primary-hover-light)
                position: relative
                column-gap: 8px

                &:last-child
                    &::before, &::after
                        content: none

                &::before, &::after
                    content: ''
                    position: absolute
                    top: 0
                    border: 16px solid transparent

                &::before
                    left: 100%
                    z-index: 3
                    border-left: 10px solid var(--color-primary-hover-light)

                &::after
                    left: calc(100% + 1px)
                    z-index: 2
                    border-left: 10px solid var(--color-primary-dark)

                .icon
                    height: 20px
                    width: auto

                .num
                    width: 16px
                    height: 16px
                    display: flex
                    align-items: center
                    justify-content: center
                    font-size: 11px
                    border-radius: 50%
                    background: var(--color-primary-dark)
                    color: var(--color-primary-hover-light)

                    svg
                        display: none

                p
                    color: var(--color-primary-dark)
                    font-weight: 600
                    font-size: 14px


            .stepper-navigation-item.confirmed
                background: var(--color-primary)

                &::before
                    border-left: 10px solid var(--color-primary)

                &::after
                    border-left: 10px solid var(--background-white)

                .num
                    background: var(--background-white)

                    svg
                        display: block

                    span
                        display: none

                p
                    color: var(--text-color-white)


</style>
