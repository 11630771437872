<template>
    <div v-if="item" class="map-with-point" :style="style">
        <div class="google-map" ref="googleSmallMap"></div>

        <div class="zoom-btn" @click="zoom = !zoom">
            <svg v-if="!zoom" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 18V16H3.41L7.91 11.5L6.5 10.09L2 14.59V11H0V18H7ZM11.5 7.91L16 3.41V7H18V0H11V2H14.59L10.09 6.5L11.5 7.91Z" fill="#1A1C1E"/>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.42604 21L8.97724 16.4488V20.0796H11V13H3.92035V15.0228H7.5512L3 19.574L4.42604 21Z" fill="#1A1C1E"/>
                <path d="M19.574 3L15.0228 7.5512V3.92035H13V11L20.0796 11V8.97724H16.4488L21 4.42604L19.574 3Z" fill="#1A1C1E"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import {toRaw} from "vue";
    import mapStyle from '../../assets/json/map_style';
    import {Loader} from '@googlemaps/js-api-loader';
    export default {
        props: {
            item: {
                type: Object
            }
        },

        data() {
            return {
                google: null,
                map: null,
                marker: null,

                zoom: false
            }
        },

        computed: {
            style() {
                if(!this.zoom) {
                    return {
                        width: '304px',
                        height: '126px'
                    }
                }
                else {
                    return {
                        width: '544px',
                        height: '226px'
                    }
                }
            }
        },

        watch: {
            item() {
                if(this.marker) {
                    this.removeMarker(this.marker);
                    this.addMarker();
                    this.map.setCenter({
                        lat: Number(this.item.latitude),
                        lng: Number(this.item.longitude),
                    });
                    this.map.setZoom(16)
                }
            },
        },

        mounted() {
            const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
            const loaderMap = new Loader({
                apiKey: key,
                version: "weekly",
            });
            this.initializeMap(loaderMap);
        },

        methods: {
            initializeMap(loader) {
                const mapContainer = this.$refs.googleSmallMap;
                let center;
                let map;
                let self = this;

                loader
                    .load()
                    .then((google) => {
                        this.google = google;

                        center = new google.maps.LatLng(this.item.latitude, this.item.longitude);

                        map = new google.maps.Map(mapContainer, {
                            mapTypeIds: 'styled_map',
                            streetViewControl: false,
                            zoom: 16,
                            minZoom: 3,
                            maxZoom: 22,
                            center: center,
                            fullscreenControl: false,
                            disableDefaultUI: true,
                            styles: mapStyle
                        });

                        self.map = map;
                        this.addMarker();
                    });
            },

            addMarker() {
                let icon = {
                    anchor: new this.google.maps.Point(24, 24),
                    url: `./../../../img/assets/212_selected.png`,
                };

                const new_marker = new this.google.maps.Marker({
                    position: new this.google.maps.LatLng(
                        this.item.latitude,
                        this.item.longitude
                    ),
                    lat: this.item.latitude,
                    lng: this.item.longitude,
                    title: this.item.name ? this.item.name : String(this.item.deviceid),
                    deviceId: this.item.deviceid,
                    deviceType: this.item.deviceType,
                    zIndex: 1,
                    icon: {
                        anchor: icon.anchor,
                        url: icon.url,
                    },
                    optimized: true
                });
                this.marker = new_marker;
                toRaw(new_marker).setMap(this.map);
            },

            removeMarker(marker) {
                toRaw(marker).setMap(null);
            }
        }
    }
</script>

<style scoped lang="sass">
    .map-with-point
        overflow: hidden
        border-radius: 4px
        position: relative
        transition: 0.4s ease-in-out
        margin-bottom: 16px

        .google-map
            width: 100%
            height: 100%

        .zoom-btn
            z-index: 2
            width: 40px
            height: 40px
            background-color: var(--background-light)
            display: flex
            align-items: center
            justify-content: center
            position: absolute
            right: 8px
            top: 8px
            border-radius: 8px
            box-shadow: 0 6px 8px -6px rgba(24, 39, 75, 0.12), 0 8px 16px -6px rgba(24, 39, 75, 0.08)
            cursor: pointer
</style>
