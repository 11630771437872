<template>
    <div class="input-element"
         :class="{'input-element-solo': solo, 'input-element-no-solo': !solo, 'input--error': default_error, 'input-disabled': disabled, 'input--small': small}">

        <label :for="id" v-if="label">{{label}} <span v-if="required && !disabled" class="error-color">*</span></label>
        <div class="input-container">
            <input :id="id"
                   :type="default_type === 'date' ? 'text' : default_type"
                   v-model="value"
                   :disabled="disabled"
                   :readonly="default_type === 'date'"
                   @keyup.enter="onEnter"
                   @focus="onFocus" @input="inputData"
                   :placeholder="placeholder">

            <svg v-if="default_error" class="icon-error" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM10.5844 10.6594L9.55313 10.6547L8 8.80313L6.44844 10.6531L5.41563 10.6578C5.34688 10.6578 5.29063 10.6031 5.29063 10.5328C5.29063 10.5031 5.30156 10.475 5.32031 10.4516L7.35313 8.02969L5.32031 5.60938C5.30143 5.58647 5.29096 5.5578 5.29063 5.52812C5.29063 5.45937 5.34688 5.40312 5.41563 5.40312L6.44844 5.40781L8 7.25938L9.55156 5.40938L10.5828 5.40469C10.6516 5.40469 10.7078 5.45937 10.7078 5.52969C10.7078 5.55937 10.6969 5.5875 10.6781 5.61094L8.64844 8.03125L10.6797 10.4531C10.6984 10.4766 10.7094 10.5047 10.7094 10.5344C10.7094 10.6031 10.6531 10.6594 10.5844 10.6594Z"
                      fill="#DD3730"/>
            </svg>

            <svg v-if="icon === 'username'" class="icon-default" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5.9C13.16 5.9 14.1 6.84 14.1 8C14.1 9.16 13.16 10.1 12 10.1C10.84 10.1 9.9 9.16 9.9 8C9.9 6.84 10.84 5.9 12 5.9ZM12 14.9C14.97 14.9 18.1 16.36 18.1 17V18.1H5.9V17C5.9 16.36 9.03 14.9 12 14.9ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13Z"
                      fill="#2A54CD"/>
            </svg>

            <svg v-if="icon === 'password' && default_type === 'password'" @click="setPassType()"
                 class="icon-default icon-action" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.79 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                      fill="#45464E"/>
            </svg>
            <svg v-if="icon === 'password' && default_type === 'text'" @click="setPassType()"
                 class="icon-default icon-action" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5.97499C15.79 5.97499 19.17 8.10499 20.82 11.475C20.23 12.695 19.4 13.745 18.41 14.595L19.82 16.005C21.21 14.775 22.31 13.235 23 11.475C21.27 7.08499 17 3.97499 12 3.97499C10.73 3.97499 9.51 4.17499 8.36 4.54499L10.01 6.19499C10.66 6.06499 11.32 5.97499 12 5.97499ZM10.93 7.11499L13 9.18499C13.57 9.43499 14.03 9.89499 14.28 10.465L16.35 12.535C16.43 12.195 16.49 11.835 16.49 11.465C16.5 8.98499 14.48 6.97499 12 6.97499C11.63 6.97499 11.28 7.02499 10.93 7.11499ZM2.01 3.84499L4.69 6.52499C3.06 7.80499 1.77 9.50499 1 11.475C2.73 15.865 7 18.975 12 18.975C13.52 18.975 14.98 18.685 16.32 18.155L19.74 21.575L21.15 20.165L3.42 2.42499L2.01 3.84499ZM9.51 11.345L12.12 13.955C12.08 13.965 12.04 13.975 12 13.975C10.62 13.975 9.5 12.855 9.5 11.475C9.5 11.425 9.51 11.395 9.51 11.345ZM6.11 7.94499L7.86 9.69499C7.63 10.245 7.5 10.845 7.5 11.475C7.5 13.955 9.52 15.975 12 15.975C12.63 15.975 13.23 15.845 13.77 15.615L14.75 16.595C13.87 16.835 12.95 16.975 12 16.975C8.21 16.975 4.83 14.845 3.18 11.475C3.88 10.045 4.9 8.86499 6.11 7.94499Z"
                      fill="#45464E"/>
            </svg>

            <svg v-if="icon === 'search'" class="icon-default pointer" @click="onSearch()" width="18" height="18"
                 viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8164 10.6914H11.2239L11.0139 10.4889C11.7489 9.63391 12.1914 8.52391 12.1914 7.31641C12.1914 4.62391 10.0089 2.44141 7.31641 2.44141C4.62391 2.44141 2.44141 4.62391 2.44141 7.31641C2.44141 10.0089 4.62391 12.1914 7.31641 12.1914C8.52391 12.1914 9.63391 11.7489 10.4889 11.0139L10.6914 11.2239V11.8164L14.4414 15.5589L15.5589 14.4414L11.8164 10.6914ZM7.31641 10.6914C5.44891 10.6914 3.94141 9.18391 3.94141 7.31641C3.94141 5.44891 5.44891 3.94141 7.31641 3.94141C9.18391 3.94141 10.6914 5.44891 10.6914 7.31641C10.6914 9.18391 9.18391 10.6914 7.31641 10.6914Z"
                      fill="#1A1C1E"/>
            </svg>

            <svg v-if="type === 'date' && !disabled" @click="onFocus()" class="icon-default" width="18" height="18"
                 viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 8.25H5.25V9.75H6.75V8.25ZM9.75 8.25H8.25V9.75H9.75V8.25ZM12.75 8.25H11.25V9.75H12.75V8.25ZM14.25 3H13.5V1.5H12V3H6V1.5H4.5V3H3.75C2.9175 3 2.2575 3.675 2.2575 4.5L2.25 15C2.25 15.825 2.9175 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM14.25 15H3.75V6.75H14.25V15Z"
                      fill="#2A54CD"/>
            </svg>

            <svg  v-if="icon === 'reset' && modelValue" @click="value = null" class="icon-default"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99967 1.3335C4.31301 1.3335 1.33301 4.3135 1.33301 8.00016C1.33301 11.6868 4.31301 14.6668 7.99967 14.6668C11.6863 14.6668 14.6663 11.6868 14.6663 8.00016C14.6663 4.3135 11.6863 1.3335 7.99967 1.3335ZM11.333 10.3935L10.393 11.3335L7.99967 8.94016L5.60634 11.3335L4.66634 10.3935L7.05967 8.00016L4.66634 5.60683L5.60634 4.66683L7.99967 7.06016L10.393 4.66683L11.333 5.60683L8.93967 8.00016L11.333 10.3935Z" fill="#45464E"/>
            </svg>

        </div>

        <div v-if="default_error_msg && default_error" class="error-msg">{{default_error_msg}}</div>

        <div class="date_picker_overlay" v-if="type === 'date' && date_picker">
            <div class="date_picker_card">
                <i-button @click="date_picker = false" icon img="/svg/close.svg"></i-button>
                <DatePicker :model-config="modelConfig" v-model="value"
                            :locale="{ id: 'en', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }"/>
                <div class="button-container">
                    <i-button small @click="date_picker = false" :title="$t('buttons.1')"></i-button>
                </div>
            </div>
        </div>

        <div v-if="loading" class="loading">
            <svg version="1.1" id="L4" class="loader" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                 y="0px"
                 viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                  <circle fill="#2A54CD" stroke="none" cx="30" cy="50" r="6">
                    <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.1"/>
                  </circle>
                <circle fill="#2A54CD" stroke="none" cx="50" cy="50" r="6">
                    <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.2"/>
                  </circle>
                <circle fill="#2A54CD" stroke="none" cx="70" cy="50" r="6">
                    <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.3"/>
                  </circle>
            </svg>
        </div>

    </div>
</template>

<script>
    import {DatePicker} from 'v-calendar';
    import 'v-calendar/style.css';

    export default {
        components: {
            DatePicker,
        },
        props: {
            modelValue: {
                default: null
            },
            id: {
                default: 1
            },
            label: {
                type: String
            },
            placeholder: {
                default: null
            },
            type: {
                default: 'text'
            },
            solo: {
                type: Boolean,
                default: true
            },
            error: {
                type: Boolean,
                default: false
            },
            errorMsg: {
                type: String,
                default: null
            },
            icon: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:modelValue', 'on_search'],
        data() {
            return {
                date_picker: false,
                modelConfig: {
                    type: 'string',
                    mask: 'DD/MM/YYYY'
                },

                default_type: 'text',
                default_error: false,
                default_error_msg: null,
            }
        },

        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        },

        watch: {
            error() {
                this.default_error = this.error;
            },
            errorMsg() {
                this.default_error_msg = this.errorMsg;
            }
        },

        mounted() {
            this.default_type = this.type;
            this.default_error = this.error;
            this.default_error_msg = this.errorMsg;
        },

        methods: {
            onFocus() {
                if (this.default_type === 'date') {
                    this.date_picker = !this.date_picker;
                }
            },

            onSearch() {
                this.$emit('on_search', this.value)
            },

            setPassType() {
                if (this.default_type == 'password') {
                    this.default_type = 'text';
                    return;
                } else this.default_type = 'password'
            },

            onEnter() {
                if(this.icon === 'search') this.onSearch();
            }
        }
    }
</script>
