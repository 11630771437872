import {createRouter, createWebHistory} from 'vue-router'
import IndexApp from '../views/IndexApp.vue';

import IndexAuth from '../views/auth/IndexAuth.vue'
import IndexReports from '../views/reports/IndexReports.vue'
import IndexActivities from '../views/activities/IndexActivities.vue'
import IndexError404 from '../views/errors/IndexError404.vue'

const routes = [
    {
        path: '/',
        name: 'index',
        component: IndexApp,
        meta: {
            title: "AIS - Grid Mapping",
            requiresAuth: true
        }
    },

    /*auth*/
    {
        path: '/login',
        name: 'login',
        component: IndexAuth
    },
    {
        path: '/recover',
        name: 'recover',
        component: IndexAuth,
        meta: {
            title: "AIS - Recover Password",
        }
    },

    /*reports*/
    {
        path: '/reports',
        name: 'reports',
        component: IndexReports
    },
    /*activities*/
    {
        path: '/activities',
        name: 'activities',
        component: IndexActivities
    },

    /*error*/
    {
        path: '/:pathMatch(.*)*',
        component: IndexError404,
        meta: {
            title: "AIS - Page Error",
            requiresAuth: true
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;

    /*redirect to account from login or register */
    if ((to.name === 'login' || to.name === 'recover') && localStorage.getItem('token')) {
        window.location.pathname = "/";
    }

    /*check login*/
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            window.location.pathname = "/login";
        }
        if (localStorage.getItem('token') && (!localStorage.getItem('city') || !localStorage.getItem('country'))) {
            localStorage.removeItem('token');
            window.location.pathname = "/login";
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router
