<template>
    <div id="content" class="content-page">
        <div class="dialog center" v-if="loading">
            <div class="dialog-card">
                <div class="dialog-header">
                    Loading data...
                </div>
                <div class="dialog-body">
                    <i-loading></i-loading>
                </div>
            </div>
        </div>

        <div class="loading" v-if="loading_item">
            <i-loading></i-loading>
        </div>

        <div class="statistic-page">
            <div class="heading">
                <div class="container">
                    <div class="d-flex align-center justify-between">
                        <div>
                            <p class="font-600 primary-tonal mb-1">AIS</p>
                            <router-link to="/" class="d-flex align-center mb-6">
                                <svg class="flex-shrink-0 me-3" width="28" height="28" viewBox="0 0 28 28" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="28" rx="14" fill="#DBE1FF"/>
                                    <path d="M15.9996 17.2292L12.7663 13.9958L15.9996 10.7625C16.3246 10.4375 16.3246 9.9125 15.9996 9.5875C15.6746 9.2625 15.1496 9.2625 14.8246 9.5875L10.9996 13.4125C10.6746 13.7375 10.6746 14.2625 10.9996 14.5875L14.8246 18.4125C15.1496 18.7375 15.6746 18.7375 15.9996 18.4125C16.3163 18.0875 16.3246 17.5542 15.9996 17.2292Z"
                                          fill="#001452"/>
                                </svg>
                                <p class="fs-36">Activities</p>
                            </router-link>
                        </div>
                        <i-button
                                @click="exportData()"
                                img="/svg/file_upload.svg"
                                color="secondary light"
                                title="Export"
                                medium
                        ></i-button>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="d-flex mb-4">
                    <i-select
                            v-model="filterData.days"
                            :options="daysOptions"
                            class="me-2"
                            title="Name"
                            option_key="Code"
                            deselect
                            @change="selectChange"
                            :rangeDateSelect="rangeDateString">
                    </i-select>

                    <i-select
                            v-model="filterData.UserID"
                            :options="responsibleOptions"
                            title="name"
                            class="me-2"
                            option_key="userId"
                            placeholder="Responsible"
                    ></i-select>
                    <i-select
                            v-model="filterData.action"
                            :options="actionOptions"
                            title="name"
                            option_key="value"
                            placeholder="Action"
                    ></i-select>
                </div>

                <div class="data-card data-card--table" v-if="results">
                    <div class="data-card--filter">
                        <div class="data-card--filter-items">
                            <div class="font-600">Results: {{count}}</div>
                            <div class="d-flex align-center">
                                <p>Show:</p>
                                <i-select v-if="limitOptions"
                                          title="title"
                                          v-model="filterData.limit"
                                          :options="limitOptions">
                                </i-select>
                            </div>
                        </div>
                        <div v-if="selected_results && selected_results.length">
                            <i-button @click="removeSelected()" text title="Remove selected"></i-button>
                        </div>
                    </div>
                    <el-table ref="table"
                              :data="filteredItems"
                              :maxHeight="tableHeight"
                              empty-text="No data"
                              :row-class-name="tableRowClassName"
                              @selection-change="handleSelectionChange"
                              @sort-change="sortChange($event)">
                        <el-table-column type="selection" width="55"/>
                        <el-table-column property="name" sortable label="Name"/>
                        <el-table-column property="barcode" sortable label="Barcode" width="200"/>
                        <el-table-column property="responsible" sortable label="Reponsible"/>
                        <el-table-column property="actionDate" sortable label="Date">
                            <template #default="item">
                                <div>{{getDate(item.row.actionDate)}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="actionName" label="Status" width="120"/>
                        <el-table-column label="" width="80">
                            <template #default="item">
                                <div class="table-actions">
                                    <i-button @click="detailsItem(item.row)" icon img="/svg/edit.svg"></i-button>
                                    <i-button @click="removeItem(item.row)" icon img="/svg/remove.svg"></i-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="pagination_container"  v-if="results && results.length > filterData.limit && filterData.limit != 0">
                    <button class="pagination_nav" :class="{'disabled': filterData.page == 1}" :disabled="filterData.page == 1" @click="changeCurrentPage(1)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6584 15L15.8334 13.825L12.0167 10L15.8334 6.175L14.6584 5L9.65841 10L14.6584 15Z" fill="#5D5E61"/>
                            <path d="M9.16675 15L10.3417 13.825L6.52508 10L10.3417 6.175L9.16675 5L4.16675 10L9.16675 15Z" fill="#5D5E61"/>
                        </svg>
                    </button>
                    <el-pagination
                            layout="prev, pager, next"
                            :total="count"
                            :page-size="filterData.limit"
                            @current-change="changeCurrentPage"
                            :current-page="filterData.page"
                    />
                    <button  class="pagination_nav" :class="{'disabled': filterData.page == lastPage}" :disabled="filterData.page == lastPage" @click="changeCurrentPage(lastPage)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.34175 5L4.16675 6.175L7.98341 10L4.16675 13.825L5.34175 15L10.3417 10L5.34175 5Z" fill="#5D5E61"/>
                            <path d="M10.8334 5L9.65841 6.175L13.4751 10L9.65841 13.825L10.8334 15L15.8334 10L10.8334 5Z" fill="#5D5E61"/>
                        </svg>
                    </button>
                </div>

            </div>
        </div>

        <activities-details-dialog v-if="activeDevice"
                                   :item="activeDevice"
                                   @cancel_data="cancelData">
        </activities-details-dialog>

        <div class="date_picker_overlay date_picker__range" v-if="date_picker">
            <div class="date_picker_card">
                <i-button @click="clearRangeDate(true)" icon img="/svg/close.svg"></i-button>
                <p class="fs-12 font-500 text-gray-dark mb-2">Select range</p>
                <div class="d-flex align-center" v-if="rangeDate.start">
                    <p class="fs-24">{{ monthDayFormat(rangeDate.start) }} - {{ monthDayFormat(rangeDate.end) }}</p>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M32.71 17.6305C33.1 18.0205 33.1 18.6505 32.71 19.0405L30.88 20.8705L27.13 17.1205L28.96 15.2905C29.35 14.9005 29.98 14.9005 30.37 15.2905L32.71 17.6305ZM15 33.0005V29.2505L26.06 18.1905L29.81 21.9405L18.75 33.0005H15Z"
                              fill="#45464E"/>
                    </svg>
                </div>
                <DatePicker
                        v-model="rangeDate"
                        is-range
                        :locale="{ id: 'en', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }"
                        :columns="2"
                        :min-date="minCalendarDate"
                        :max-date='new Date()'
                />
                <div class="button-container">
                    <i-button small @click="setRangeDate()" :title="'Confirm'"></i-button>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {DatePicker} from 'v-calendar';
    import {ElTable, ElTableColumn, ElPagination} from 'element-plus';
    import moment from 'moment';

    import ActivitiesDetailsDialog from '../../components/dialogs/ActivitiesDetailsDialog'

    export default {
        components: {ElTable, ElTableColumn, ElPagination, DatePicker, ActivitiesDetailsDialog},
        data() {
            return {
                loading: true,
                loading_item: false,

                results: null,
                results_default: null,
                date_picker: false,

                selected_results: [],

                filterData: {
                    days: 6,
                    action: null,
                    UserID: null,
                    page: 1,
                    limit: 15
                },
                rangeDate: {
                    start: null,
                    end: null
                },
                rangeDateSelect: {
                    start: null,
                    end: null
                },

                daysOptions: [
                    {Name: '24 Hours', Code: 1},
                    {Name: 'Last 7 Days', Code: 6},
                    {Name: 'Last 30 Days', Code: 29},
                    {Name: 'Custom Range', Code: 0}
                ],
                responsibleOptions: [],
                actionOptions: [
                    {name: 'Insert', value: '1'},
                    {name: 'Update', value: '2'},
                    {name: 'Delete', value: '3'}
                ],
                limitOptions: [
                    {value: 10, title: 10},
                    {value: 15, title: 15},
                    {value: 20, title: 20},
                    {value: 30, title: 30},
                    {value: 0, title: 'All'},
                ],

                activeDevice: null,
                form_error: false,
            }
        },

        computed: {
            filteredItems() {
                let results = this.results;
                let limit = this.filterData.limit == 0 ? this.results.length : this.filterData.limit;
                let data = [];
                if (results) {
                    /*pagination*/
                    let index = this.filterData.page * limit - limit;
                    data = results.slice(index, index + limit);
                }

                return data
            },
            count() {
                return this.results ? this.results.length : null;
            },
            filter() {
                let data = {
                    dateTo: null,
                    dateFrom: null,
                    actionID: this.filterData.action,
                    userID: this.filterData.UserID ? this.filterData.UserID : null
                };
                if (this.filterData.days && this.filterData.days != 0) {
                    data.dateFrom = moment().subtract(this.filterData.days, 'days').format('MM/DD/YYYY 00:00:00Z');
                    data.dateTo = moment(new Date()).format('MM/DD/YYYY 23:59:59Z');
                }
                if (!this.filterData.days || (this.filterData.days && this.filterData.days == 0)) {
                    if (this.rangeDateSelect.start) data.dateFrom = moment(this.rangeDateSelect.start).format('MM/DD/YYYY 00:00:00Z');
                    if (this.rangeDateSelect.end) data.dateTo = moment(this.rangeDateSelect.end).format('MM/DD/YYYY 23:59:59Z');
                }
                return data
            },

            user() {
                return this.$store.getters.getUser;
            },
            rangeDateString() {
                if (this.rangeDateSelect.start) {
                    let start = moment(this.rangeDateSelect.start).format('MM/DD/YYYY');
                    let end = moment(this.rangeDateSelect.end).format('MM/DD/YYYY');
                    return `${start} - ${end}`
                } else return null;
            },
            tableHeight() {
                let doc_height = document.documentElement.clientHeight;
                let el_height = 72 + 48 + 98 + 16 + 60 + 50 + 42;
                return doc_height - el_height;
            },

            lastPage() {
                let result = this.count / this.filterData.limit;
                return Math.ceil(result);
            },

            minCalendarDate() {
                return moment().subtract(92, 'days').format('MM/DD/YYYY 00:00:00Z')
            },
        },

        watch: {
            filter() {
                this.init();
            },
        },

        mounted() {
            this.init();
            this.getResponsibleOptions();
        },

        methods: {
            init() {
                if (this.filter.dateFrom && this.filter.dateTo) {
                    this.loading = true;
                    let url = '/water/getReportDetail';
                    this.axios.get(url, {params: this.filter})
                        .then(response => {
                            if (response) {
                                this.loading = false;
                                this.results = response.data;
                                this.results_default = response.data;
                            }
                        })
                        .catch((error => {
                            if (error) this.loading = false;
                        }))
                }
            },

            getResponsibleOptions() {
                let params = {
                    areaID: localStorage.getItem('areaid')
                };
                let url = '/getUsers';
                this.axios.get(url, {params: params})
                    .then(response => {
                        if (response) {
                            this.responsibleOptions = response.data.map(el => {
                                return {
                                    name: el.userName,
                                    userId: el.userID
                                }
                            });
                        }
                    })
            },

            selectChange(e) {
                if (e.Code == 0) {
                    this.date_picker = true;
                } else this.clearRangeDate();
            },

            setRangeDate() {
                this.rangeDateSelect = {
                    start: this.rangeDate.start,
                    end: this.rangeDate.end,
                };
                this.date_picker = false;
            },

            clearRangeDate(from_date) {
                if (from_date) this.filterData.days = 6;
                this.rangeDate = {
                    start: null,
                    end: null,
                };
                this.rangeDateSelect = {
                    start: null,
                    end: null,
                };
                this.date_picker = false;
            },

            monthDayFormat(date) {
                if (date) return moment(date).format('MMM DD')
            },

            getDate(date) {
                if (date) return moment(date).format('DD.MM.YYYY')
            },

            sortChange(e) {
                if (e.order) {
                    this.results.sort((a, b) => a[e.prop].localeCompare(b[e.prop], undefined, {
                        numeric: true,
                        sensitivity: "base"
                    }));
                    if (e.order == 'descending') {
                        this.results.reverse();
                    }
                } else {
                    this.results = [...this.results_default];
                }
            },
            tableRowClassName(row) {
                let result = '';
                if (row && this.selected_results && this.selected_results.length) {
                    let selected_index = this.selected_results.findIndex(item => item.id == row.row.id && item.ActionDate == row.row.ActionDate);
                    if (selected_index > -1) result = 'active';
                }
                return result;
            },
            handleSelectionChange(e) {
                this.selected_results = e;
            },

            changeCurrentPage(e) {
                this.filterData.page = e;
            },

            removeSelected() {
                let text = 'Are you sure you want to delete selected elements and all related data? You cannot undo this action.';
                this.$swal.fire({
                    icon: false,
                    title: 'Delete activities',
                    html: text,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete',
                    customClass: {
                        cancelButton: 'remove',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        let endpoints = [];
                        this.selected_results.forEach(item => {
                            let url = `/water/servicePoint/remove/${item.ID}`;
                            if (!endpoints.find(point => point === url)) endpoints.push(url)
                        });
                        if (endpoints && endpoints.length) {
                            Promise.all(endpoints.map((endpoint) => this.axios.delete(endpoint))).then(
                                () => {
                                    this.mainNotification('OK');
                                    this.selected_results = [];
                                    this.init();
                                }
                            );
                        }
                    }
                })
            },

            removeItem(e) {
                let text = 'Are you sure you want to delete the following element and all related data? You cannot undo this action.';
                this.$swal.fire({
                    icon: false,
                    title: 'Delete activities',
                    html: text,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete',
                    customClass: {
                        cancelButton: 'remove',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        let url = `/water/servicePoint/remove/${e.ID}`;
                        this.axios.delete(url)
                            .then(response => {
                                if (response) {
                                    this.mainNotification(response.data.message);
                                    this.init();
                                }
                            });
                    }
                })
            },

            detailsItem(e) {
                this.setLoading(true);
                let url = `water/getInfoDevice/${e.ID}`;
                this.axios.get(url)
                    .then(response => {
                        if (response) {
                            this.activeDevice = response.data;
                            this.setLoading(false);
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            this.mainNotification(error.response.data.message);
                            this.setLoading(false)
                        }
                    })
            },

            setLoading(value) {
                this.loading_item = value;
            },

            cancelData() {
                this.activeDevice = null;
                this.form_error = false;
            },

            exportData() {
                if (this.filter.dateFrom && this.filter.dateTo) {
                    // if (this.filterData.responsiblePie) {
                    //     params.UserID = this.filterData.responsiblePie;
                    // }

                    this.axios.post(`/water/exportReportDetail`, this.filter, {responseType: 'blob'})
                        .then(response => {
                            if (response) {
                                let url = URL.createObjectURL(new Blob([response.data], {
                                    type: 'application/excel'
                                }));
                                let link = document.createElement('a');
                                link.style.position = 'absolute';
                                link.style.left = '-9999px';
                                link.href = url;
                                link.setAttribute('download', 'UserReport.xls');
                                document.body.appendChild(link);
                                link.click();
                            }
                        })
                        .catch(error => {
                            if (error) {
                                console.log(error);
                            }
                        })
                }
            },

        }
    }
</script>
