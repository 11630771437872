export default {
    actions: {
        async fetchUser(ctx) {
            if(localStorage.getItem('token')) {
                let user = {
                    areaid: localStorage.getItem('areaid'),
                    city: localStorage.getItem('city'),
                    country: localStorage.getItem('country'),
                    id: localStorage.getItem('user_id'),
                };
                ctx.commit('setUser', user);
            }
            else ctx.commit('setUser', null);
        }
    },

    mutations: {
        setUser(state, user) {
            state.user = user
        }
    },

    state: {
        user: null,
    },

    getters: {
        getUser(state) {
            return state.user
        }
    },
}
