<template>
    <div  class="dialog-side left" id="dialog-results">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div>
                    <div class="card-drag" id="drag-results">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                </div>

                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>

            <div class="dialog-body">
                <div class="dialog-title mb-12">
                    <span class="fs-28">Search results</span>
                </div>

                <div class="dialog-content dialog-content-scroll">
                    <div class="result-items" v-if="items_default && items_default.length">
                        <div class="result-item" :class="{'active': active_device_id == item.deviceID ? item.deviceID : item.ID}" v-for="(item, i) in items_default" :key="i" @click="setActiveDevice(item.deviceID ? item.deviceID : item.ID)">
                            <img src="/svg/assets/212_single.svg" alt="">
                            <span class="font-600 fs-14">Barcode {{item.barcode}}</span>
                        </div>
                    </div>

                    <div v-if="!items_default || (items_default && !items_default.length)" class="no-results-container">
                        <div class="icon mb-4">
                            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 30.5H26V34.5H22V30.5ZM22 14.5H26V26.5H22V14.5ZM23.98 4.5C12.94 4.5 4 13.46 4 24.5C4 35.54 12.94 44.5 23.98 44.5C35.04 44.5 44 35.54 44 24.5C44 13.46 35.04 4.5 23.98 4.5ZM24 40.5C15.16 40.5 8 33.34 8 24.5C8 15.66 15.16 8.5 24 8.5C32.84 8.5 40 15.66 40 24.5C40 33.34 32.84 40.5 24 40.5Z" fill="#2A54CD"/>
                            </svg>
                        </div>

                        <div class="font-600 primary mb-4 text-center">No matching items found</div>
                        <p class="text-center font-600 fs-14">Check the spelling of your keywords. Try adjusting your search or filter to find relevant results</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            items: {
                default: null
            }
        },

        data() {
            return {
                items_default: null,
                active_device_id: null,
            }
        },

        watch: {
            items() {
                this.items_default= this.items;
                this.active_device_id = null;
            }
        },

        mounted() {
            this.items_default= this.items;

            Draggable.create("#dialog-results", {
                trigger: "#drag-results",
                bounds: ".app",
                edgeResistance: 0.65,
                inertia: true,
                autoScroll: 1,
            });
        },

        methods: {
            setActiveDevice(id) {
                this.active_device_id = id;
                this.$emit('set_active_device', id, 'results');
            },
            closeDialog() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="sass" scoped>
    .no-results-container
        border: 1px solid var(--border-middle)
        border-radius: 12px
        padding: 12px
        text-align: center
        p
            max-width: 240px
            margin: 0 auto

</style>
