<template>
    <header>
        <div class="container header-container">
            <div class="d-flex align-center">
                <router-link class="me-12 d-block" to="/"><img src="/img/logo.svg" alt="" width="48" height="48">
                </router-link>

                <i-dropdown title="AIS" class="custom-menu me-4">
                    <template v-slot:menu>
                        <ul class="custom-menu">
                            <li v-for="(item, index) in dropdown" :key="index">
                                <a :href="item.href" class="dropdown--item" :class="item.class" target="_blank"
                                   rel="noopener">{{item.title}}</a>
                            </li>
                        </ul>
                    </template>
                </i-dropdown>

                <nav>
                    <router-link to="/">Grid Mapping</router-link>
                    <router-link to="/reports">Reports</router-link>
                    <router-link to="/activities">Activities</router-link>
                </nav>
            </div>

            <div class="d-flex align-center">
                <div class="search-container me-5">
                    <i-input v-model="filterData.search"
                             id="search"
                             icon="search"
                             ref="search"
                             :loading="search_loading"
                             @on_search="onSearch"
                             :placeholder="'Type something...'"
                    ></i-input>

                    <div class="search-button">
                        <i-button middle inline color="secondary" @click="showSearchDialog" :title="'Advanced'"></i-button>
                    </div>
                </div>

                <div>
                    <i-dropdown img="/img/avatar.png" class="no-border right">
                        <template v-slot:menu>
                            <ul class="custom-menu">
                                <li><span class="dropdown--item" @click="logOut()">Log out</span></li>
                            </ul>
                        </template>
                    </i-dropdown>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                search_loading: false,
                filterData: {
                    search: null
                },

                dropdown: [
                    {'href': '/', title: 'HES'},
                    {'href': '/', title: 'Dashboard'},
                    {'href': '/', title: 'MDM'},
                    {'href': '/', title: 'Vending'},
                    {'href': '/', title: 'Workorder'},
                    {'href': '/', title: 'Assets'},
                    {'href': '/', title: 'Inventory'},
                    {'href': '/', title: 'Users&Permissions'},
                    {'href': '#', title: 'AIS', class: 'active'}
                ],
            }
        },

        methods: {
            onSearch(e) {
                if(e) {
                    if(this.$route.name !== 'index') this.$router.push({name: 'index'});
                    this.search_loading = true;
                    let data = {
                        nameOrBarcode: e
                    };
                    let url = '/water/getAllDevicesByName';
                    this.axios.get(url, {params: data})
                        .then(response => {
                            if(response) {
                                this.search_loading = false;
                                this.emitter.emit("searchResultsData", response.data);
                                this.filterData.search = null;
                            }
                            else this.loading = false;
                        })
                        .catch((error) => {
                            if(error) {
                                this.emitter.emit("searchResultsData", null);
                                this.search_loading = false;
                                this.filterData.search = null;
                            }
                        })
                }
            },
            showSearchDialog() {
                this.$emit('show_search_dialog');
            },
            logOut() {
                this.$swal.fire({
                    icon: false,
                    title: 'Log out',
                    text: 'Really want to log out of your account?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: 'Yes, log out'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('areaid');
                        localStorage.removeItem('city');
                        localStorage.removeItem('country');
                        window.location.pathname = "/login";
                    }
                })
            }
        }
    }
</script>

<style lang="sass">
    header
        .dropdown.custom-menu
            ul
                display: grid
                grid-template-columns: repeat(2, 296px)

                li
                    a
                        background: var(--background-common)
                        font-weight: 600
                        user-select: none
                        color: var(--text-color-gray-dark)

                    a:not(.active)
                        &:hover
                            background: var(--color-primary-hover-light)
                            color: var(--color-primary)

                    a.active
                        background: var(--color-primary-hover-light)
                        cursor: default

</style>
