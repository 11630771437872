<template>
    <div class="dialog medium">
        <div class="dialog-card" id="dialog-search">
            <div class="dialog-header" ref="header">
                <div>
                    <div class="card-drag" id="drag-search">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                </div>
                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>

            <div class="dialog-body">
                <div class="dialog-title mb-9">
                    <span class="fs-28 font-600">Advanced Search</span>
                    <div>
                        <i-input v-model="filterData.name" type="text" icon="search"
                                 id="name"
                                 :placeholder="'Search'"></i-input>
                    </div>
                </div>

                <div class="group-items" v-if="dropdown">
                    <i-select v-model="filterData.manufactureID"
                              :placeholder="'Manufacturer'" multiple :options="dropdown.Manufacture"
                              title="Name"
                              option_key="Code"></i-select>

                    <i-select v-model="filterData.modelID"
                              :placeholder="'Model'" multiple :options="dropdown.model_options" :no_data_text="'Select manufacturer'" title="ModelName"
                              option_key="ModelCode"></i-select>

                    <i-select v-model="filterData.pipeSize"
                              :placeholder="'Pipe Size'" multiple :options="dropdown.waterMeterPipeSize" title="Value"
                              option_key="Code"></i-select>

                    <i-select v-model="filterData.pipeType"
                              :placeholder="'Pipe Type'" multiple :options="dropdown.waterMeterPipeType" title="Value"
                              option_key="Code"></i-select>

                    <i-select v-model="filterData.fittingType"
                              :placeholder="'Fittings Type'" multiple :options="dropdown.waterMeterFittingType"
                              title="Value"
                              option_key="Code"></i-select>

                    <i-select v-model="filterData.position"
                              :placeholder="'Meter Position'" multiple :options="dropdown.waterMeterPosition"
                              title="Value"
                              option_key="Code"></i-select>

                    <i-select v-model="filterData.commType"
                              :placeholder="'Communication Type'" multiple :options="dropdown.waterMeterCommType"
                              title="Value"
                              option_key="Code"></i-select>


                    <i-select v-model="filterData.measureTypeID"
                              :placeholder="'Measure type '" multiple :options="dropdown.waterMeterMeasureType"
                              title="Value"
                              option_key="Code"></i-select>
                </div>
            </div>

            <div class="dialog-footer">
                <div></div>
                <div class="dialog-footer--items">
                    <i-button @click="clearData()" color="secondary" small :title="'Clear all'"></i-button>
                    <i-button @click="submitData()" color="primary" small :title="'Show results'"></i-button>
                </div>
            </div>

        </div>

        <div class="loading" v-if="loading || !dropdown">
            <i-loading></i-loading>
        </div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        data() {
            return {
                loading: false,
                filterData: {
                    name: null,
                    manufactureID: [],
                    modelID: [],
                    pipeSize: [],
                    pipeType: [],
                    fittingType: [],
                    commType: [],
                    position: [],
                    measureTypeID: [],
                }
            }
        },

        computed: {
            dropdown() {
                return this.$store.getters.getDropdown;
            },
            model_options() {
                let result = [];
                if(this.item_data.manufactureID && this.item_data.manufactureID == 1) result = this.dropdown.Model.Powercom;
                if(this.item_data.manufactureID && this.item_data.manufactureID == 29) result = this.dropdown.Model.Unknown;
                return result;
            }
        },

        mounted() {
            Draggable.create("#dialog-search", {
                trigger: "#drag-search",
                bounds: ".app",
                edgeResistance: 0.65,
                inertia: true,
                autoScroll: 1,
            });
            if (!this.dropdown) {
                this.$store.dispatch('fetchDropdown', this.axios);
            }
        },

        methods: {
            submitData() {
                let valid = false;
                let data = {};
                for (let i in this.filterData) {
                    if (this.filterData[i] && i !== 'name' && this.filterData[i].length) {
                        data[i] = this.filterData[i].toString();
                        valid = true;
                    }
                }
                if (this.filterData.name) {
                    data.name = this.filterData.name;
                    valid = true;
                }
                if (valid) {
                    if(this.$route.name !== 'index') this.$router.push({name: 'index'});
                    this.loading = true;
                    let url = '/water/getAllDevicesByParameters';
                    this.axios.post(url, data)
                        .then(response => {
                            if (response) {
                                this.loading = false;
                                this.emitter.emit("searchResultsData", response.data);
                                this.closeDialog();
                            } else this.loading = false;
                        })
                        .catch((error) => {
                            if (error) {
                                this.emitter.emit("searchResultsData", null);
                                this.loading = false;
                                this.closeDialog();
                            }
                        })
                }
            },

            clearData() {
                this.filterData = {
                    name: null,
                    manufactureID: [],
                    modelID: [],
                    pipeSize: [],
                    pipeType: [],
                    fittingType: [],
                    commType: [],
                    position: [],
                    measureTypeID: [],
                }
            },

            closeDialog() {
                this.$emit('close_dialog')
            }
        }
    }
</script>

