<template>
    <div>
        <div class="select-element" :class="{'select-disabled': disabled, 'active': show, 'selected': valueTitle, 'select-small': small, 'select-dark': dark,  'select-error': default_error}" ref="select">
            <div class="label" v-if="label" @click="showMenu()">{{label}} <span v-if="required && !disabled" class="error-color">*</span></div>
            <div class="select-value-title" @click="showMenu()">

                <span v-if="rangeDateSelect">{{rangeDateSelect}}</span>
                <span v-if="!rangeDateSelect && valueTitle && !multiple">{{valueTitle}}</span>
                <div v-if="!rangeDateSelect && valueTitle && valueTitle.length && multiple" class="selected-multiple">
                    <span v-if="valueTitle.length > 0" class="selected-multiple-item" @click.prevent="cancelItem(item)">{{valueTitle[0]}}</span>
                    <span v-if="valueTitle.length > 1" class="selected-multiple-item" @click.prevent="cancelItem(item)">{{valueTitle[1]}}</span>
                    <span v-if="valueTitle.length > 2" class="selected-multiple-item additional">+{{valueTitle.length - 2}}</span>
                </div>

                <span v-if="!rangeDateSelect && !valueTitle && placeholder && !multiple" class="placeholder">{{placeholder}}</span>
                <span v-if="!rangeDateSelect && multiple && placeholder" class="placeholder" :class="{'active': valueTitle && valueTitle.length}">{{placeholder}}</span>

                <svg v-if="default_error" class="icon-error" width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM10.5844 10.6594L9.55313 10.6547L8 8.80313L6.44844 10.6531L5.41563 10.6578C5.34688 10.6578 5.29063 10.6031 5.29063 10.5328C5.29063 10.5031 5.30156 10.475 5.32031 10.4516L7.35313 8.02969L5.32031 5.60938C5.30143 5.58647 5.29096 5.5578 5.29063 5.52812C5.29063 5.45937 5.34688 5.40312 5.41563 5.40312L6.44844 5.40781L8 7.25938L9.55156 5.40938L10.5828 5.40469C10.6516 5.40469 10.7078 5.45937 10.7078 5.52969C10.7078 5.55937 10.6969 5.5875 10.6781 5.61094L8.64844 8.03125L10.6797 10.4531C10.6984 10.4766 10.7094 10.5047 10.7094 10.5344C10.7094 10.6031 10.6531 10.6594 10.5844 10.6594Z"
                          fill="#DD3730"/>
                </svg>
            </div>
            <div class="select-menu" :class="{'fromBottom': fromBottom}" v-if="show">
                <div class="select-menu-header" v-if="multiple">
                    <div @click="selectAll()" class="select">Select all</div>
                    <div @click="resetAll()" class="reset">Reset</div>
                </div>
                <div class="select-menu-item"
                     :class="{'active': selectedValue(option[option_key]), 'select-menu-item-multiple': multiple}"
                     @click="select(option)"
                     v-for="option in options"
                     :key="option[option_key]">
                    {{option[title]}}
                </div>
                <div class="select-menu--no-data" v-if="!options || (options && !options.length)">{{no_data_text}}</div>
            </div>
        </div>

        <div v-if="default_error_msg && default_error" class="error-msg">{{default_error_msg}}</div>
    </div>
</template>

<script>
    export default {
        props: {
            modelValue: {},
            options: Array,
            title: {
                type: String,
                default: 'value'
            },
            option_key: {
                type: String,
                default: 'value'
            },
            id: {
                default: 1
            },
            deselect: {
                type: Boolean,
                default: false
            },
            label: {
                type: String
            },
            placeholder: {
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            rangeDateSelect: {
                type: Object, Array,
                default: null
            },
            small: {
                type: Boolean,
                default: false
            },
            dark: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            error: {
                type: Boolean,
                default: false
            },
            errorMsg: {
                type: String,
                default: null
            },
            no_data_text: {
                type: String,
                default: 'No data'
            }

        },
        emits: ['update:modelValue', 'change'],
        data() {
            return {
                show: false,

                fromBottom: false,

                default_error: null,
                default_error_msg: null,
            }
        },
        watch: {
            error() {
                this.default_error = this.error;
            },
            errorMsg() {
                this.default_error_msg = this.errorMsg;
            }
        },
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            valueTitle() {
                let result;
                if(!this.multiple && this.options) {
                    this.options.forEach(el => {
                        if (el[this.option_key] == this.value) result = el[this.title];
                    });
                }
                if(this.multiple && this.options && this.options.length) {
                    result = [];
                    this.options.forEach(el => {
                        if(this.value && this.value.includes(el[this.option_key])) result.push(el[this.title]);
                    });
                }
                return result;
            }
        },

        created() {
            document.addEventListener('click', this.closeSelect);
            this.default_error = this.error;
            this.default_error_msg = this.errorMsg;
        },

        methods: {
            showMenu() {
                let clientHeight = this.$refs.select.offsetParent.clientHeight + this.$refs.select.offsetParent.getBoundingClientRect().top;
                let offsetTop = this.$refs.select.getBoundingClientRect().top;
                if(!this.disabled) {
                    this.fromBottom = (clientHeight - offsetTop - 300) < 0;
                    this.show = !this.show;
                }
            },
            select(e) {
                if(!this.multiple){
                    this.show = !this.show;
                    if (this.value == e[this.option_key] && !this.deselect) {
                        this.value = null;
                        this.$emit('change', e);
                        return;
                    }
                    this.value = e[this.option_key]
                }
                if(this.multiple) {
                    if(this.value && this.value.includes(e[this.option_key])) {
                        let index = this.value.findIndex((x) => x == e[this.option_key]);
                        this.value.splice(index, 1);
                        return;
                    }
                    this.value.push(e[this.option_key])
                }
                this.$emit('change', e);
            },
            closeSelect(e) {
                let el = this.$refs.select;
                let target = e.target;
                if (el && el !== target && !el.contains(target)) {
                    this.show = false
                }
            },

            cancelItem(e) {
                let index = this.value.findIndex((x) => x == e);
                this.value.splice(index, 1);
            },

            selectedValue(option) {
                let result = false;
                if(!this.multiple && this.value == option) result = true;
                if(this.multiple && this.value && this.value.includes(option)) result = true;
                return result;
            },

            selectAll() {
                this.options.forEach(option => {
                    if(!this.value.includes(option[this.option_key])) this.value.push(option[this.option_key])
                });
                this.show = false;
            },

            resetAll() {
                this.value = [];
                this.show = false;
            }
        }
    }
</script>
