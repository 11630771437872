<template>

    <router-link @mousedown="ripple" v-if="to" :to="to" class="button" :class="classGenerate">
        <img v-if="img" :src="img" alt="">
        <span class="svg" v-if="svg" v-html="svg"></span>
        <div v-if="title">{{title}}</div>
    </router-link>
    <a @mousedown="ripple" v-else-if="href" :href="href" class="button" :class="classGenerate">
        <img v-if="img" :src="img" alt="">
        <span class="svg" v-if="svg" v-html="svg"></span>
        <div v-if="title">{{title}}</div>
    </a>
    <button type="button" @mousedown="ripple" v-else class="button" :disabled="loading" :class="classGenerate">
        <img v-if="img && !loading" :src="img" alt="">
        <span class="svg" v-if="svg && !loading" v-html="svg"></span>
        <div class="title" v-if="title && !loading">{{title}}</div>
        <svg v-if="loading" version="1.1" id="L4" class="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
             y="0px"
             viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
  <circle fill="#fff" stroke="none" cx="30" cy="50" r="6">
    <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"/>
  </circle>
            <circle fill="#fff" stroke="none" cx="50" cy="50" r="6">
    <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"/>
  </circle>
            <circle fill="#fff" stroke="none" cx="70" cy="50" r="6">
    <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"/>
  </circle>
</svg>

    </button>

</template>

<script>
    import effectRipple from '../../assets/packages/effectRipple.js';

    export default {
        props: {
            color: {
                type: String,
                default: 'primary'
            },
            title: {
                type: String,
            },
            to: {
                type: String,
                default: null
            },
            href: {
                type: String,
                default: null
            },
            large: {
                type: Boolean,
                default: false
            },
            middle: {
                type: Boolean,
                default: false
            },
            medium: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            extrasmall: {
                type: Boolean,
                default: false
            },
            block: {
                type: Boolean,
                default: false
            },
            text: {
                type: Boolean,
                default: false
            },
            round: {
                type: Boolean,
                default: false
            },
            chip: {
                type: Boolean,
                default: false
            },
            img: {
                type: String,
                default: null
            },
            svg: {
                type: String,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            active: {
                type: Boolean,
                default: false
            },
            icon: {
                type: Boolean,
                default: false
            },
            outline: {
                type: Boolean,
                default: false
            },
            inline: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },

        computed: {
            classGenerate() {
                let data = this.color;
                if (this.large) data = data + ' large';
                if (this.medium) data = data + ' medium';
                if (this.middle) data = data + ' middle';
                if (this.small) data = data + ' small';
                if (this.extrasmall) data = data + ' extrasmall';
                if (this.block) data = data + ' w-100';
                if (this.text) data = data + ' text--button';
                if (this.round) data = data + ' btn-round';
                if (this.outline) data = data + ' btn-outline';
                if (this.chip) data = data + ' btn-chip';
                if (this.active) data = data + ' active';
                if (this.icon) data = data + ' btn-icon';
                if (this.inline) data = data + ' inline';
                return data;
            }
        },

        methods: {
            ripple(e) {
                effectRipple(e);
            },
            // emitClick() {
            //     this.$emit('click')
            // }
        }
    }
</script>
