<template>
    <div id="content" class="content-page" v-if="user">
        <div class="dialog center" v-if="loading">
            <div class="dialog-card">
                <div class="dialog-header">
                    Loading data...
                </div>
                <div class="dialog-body">
                    <i-loading></i-loading>
                </div>
            </div>
        </div>
        <div class="statistic-page">
            <div class="heading">
                <div class="container">
                    <div class="d-flex align-center justify-between">
                        <div>
                            <p class="font-600 primary-tonal mb-1">AIS</p>
                            <router-link to="/" class="d-flex align-center mb-6">
                                <svg class="flex-shrink-0 me-3" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="28" rx="14" fill="#DBE1FF"/>
                                    <path d="M15.9996 17.2292L12.7663 13.9958L15.9996 10.7625C16.3246 10.4375 16.3246 9.9125 15.9996 9.5875C15.6746 9.2625 15.1496 9.2625 14.8246 9.5875L10.9996 13.4125C10.6746 13.7375 10.6746 14.2625 10.9996 14.5875L14.8246 18.4125C15.1496 18.7375 15.6746 18.7375 15.9996 18.4125C16.3163 18.0875 16.3246 17.5542 15.9996 17.2292Z" fill="#001452"/>
                                </svg>
                                <p class="fs-36">Reports</p>
                            </router-link>
                        </div>
                        <i-button
                                @click="exportData()"
                                img="/svg/file_upload.svg"
                                color="secondary light"
                                title="Export"
                                medium
                        ></i-button>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="data-card">
                    <div class="chart-container">
                            <div class="mb-4">
                                <p class="fs-28 text-gray-dark">Assets Added by Type</p>
                                <p class="font-600 text-gray-light" v-if="count">
                                    <span>{{ count }}</span> items
                                </p>
                                <p class="font-600 text-gray-light" v-else>No data</p>
                            </div>

                            <div class="d-flex mb-12">
                                <i-select
                                        v-model="filterData.days"
                                        :options="daysOptions"
                                        class="me-2"
                                        title="Name"
                                        option_key="Code"
                                        deselect
                                        @change="selectChange"
                                        :rangeDateSelect="rangeDateString"
                                >
                                </i-select>

                                <i-select
                                        v-model="filterData.UserID"
                                        :options="responsibleOptions"
                                        title="name"
                                        option_key="userId"
                                        placeholder="Responsible"
                                ></i-select>
                            </div>
                            <div class="box-bar-chart">
                                <div ref="barChart">
                                    <Bar class="bar-chart" :options="chartOptions"
                                         :data="chartData"/>
                                </div>

                            </div>

                        </div>
                </div>
            </div>
        </div>

        <div class="date_picker_overlay date_picker__range"  v-if="date_picker">
            <div class="date_picker_card">
                <i-button @click="clearRangeDate(true)" icon img="/svg/close.svg"></i-button>
                <p class="fs-12 font-500 text-gray-dark mb-2">Select range</p>
                <div class="d-flex align-center" v-if="rangeDate.start">
                    <p class="fs-24">{{ monthDayFormat(rangeDate.start) }} - {{  monthDayFormat(rangeDate.end) }}</p>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.71 17.6305C33.1 18.0205 33.1 18.6505 32.71 19.0405L30.88 20.8705L27.13 17.1205L28.96 15.2905C29.35 14.9005 29.98 14.9005 30.37 15.2905L32.71 17.6305ZM15 33.0005V29.2505L26.06 18.1905L29.81 21.9405L18.75 33.0005H15Z" fill="#45464E"/>
                    </svg>
                </div>
                <DatePicker
                        v-model="rangeDate"
                        is-range
                        :locale="{ id: 'en', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }"
                        :columns="2"
                        :min-date="minCalendarDate"
                        :max-date='new Date()'
                />
                <div class="button-container"><i-button small @click="setRangeDate()" :title="'Confirm'"></i-button></div>
            </div>
        </div>
    </div>
</template>

<script>

    import { DatePicker } from 'v-calendar';
    import moment from 'moment';
    import { Bar } from 'vue-chartjs'
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        BarElement,
        LinearScale,
        CategoryScale,
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, BarElement, LinearScale, Legend, ArcElement, CategoryScale);

    export default {
        components: {DatePicker, Bar},
        data() {
            return {
                loading: true,
                date_picker: false,

                results: null,
                count: 0,

                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    borderColor: '#76777A',
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            backgroundColor: '#2F3033',
                            titleColor: '#F1F0F4',
                            titleFont: {
                                family: "'IBM Plex Sans', sans-serif",
                                weight: '500',
                                size: '14px'
                            },
                            bodyColor: '#F1F0F4',
                            bodyFont: {
                                family: "'IBM Plex Sans', sans-serif",
                                weight: '400'
                            },
                            padding: 8,
                            displayColors: false,
                            callbacks: {
                                label: function (context) {
                                    return '+' + context.formattedValue + ' items';
                                },
                                title: function () {
                                    return 'Service Point';
                                }
                            },
                        }
                    },
                    scales: {
                        y: {
                            stacked: true,
                            ticks: {
                                stepSize: 50,
                                color: '#1A1C1E',
                                font: {
                                    family: "'IBM Plex Sans', sans-serif",
                                },
                            },
                        },
                        x: {
                            ticks: {
                                color: '#1A1C1E',
                                font: {
                                    family: "'IBM Plex Sans', sans-serif",
                                    size: '12px'
                                },
                            },
                        }
                    }
                },

                filterData: {
                    days: 6,
                    UserID: null
                },
                rangeDate: {
                    start: null,
                    end: null
                },
                rangeDateSelect: {
                    start: null,
                    end: null
                },

                daysOptions: [
                    {Name: '24 Hours', Code: 1},
                    {Name: 'Last 7 Days', Code: 6},
                    {Name: 'Last 30 Days', Code: 29},
                    {Name: 'Custom Range', Code: 0}
                ],

                responsibleOptions: [],
            }
        },

        computed: {
            filter() {
                let data = {
                    actionID: 1,
                    dateFrom: null,
                    dateTo: null,
                    userID: this.filterData.UserID ? this.filterData.UserID : null
                };
                if(this.filterData.days && this.filterData.days != 0) {
                    data.dateFrom = moment().subtract(this.filterData.days, 'days').format('MM/DD/YYYY 00:00:00Z');
                    data.dateTo = moment(new Date()).format('MM/DD/YYYY 23:59:59Z');
                }
                if(!this.filterData.days || (this.filterData.days && this.filterData.days == 0)) {
                    if(this.rangeDateSelect.start) data.dateFrom = moment(this.rangeDateSelect.start).format('MM/DD/YYYY 00:00:00Z');
                    if(this.rangeDateSelect.end) data.dateTo = moment(this.rangeDateSelect.end).format('MM/DD/YYYY 23:59:59Z');
                }

                return data
            },

            user() {
                return this.$store.getters.getUser;
            },
            rangeDateString() {
                if(this.rangeDateSelect.start) {
                    let start = moment(this.rangeDateSelect.start).format('MM/DD/YYYY');
                    let end = moment(this.rangeDateSelect.end).format('MM/DD/YYYY');
                    return `${start} - ${end}`
                }
                else return null;
            },
            minCalendarDate() {
                return moment().subtract(92, 'days').format('MM/DD/YYYY 00:00:00Z')
            },

            dateDifference() {
                if(!this.rangeDateSelect.start) return this.filterData.days;
                if(this.rangeDateSelect.start) {
                    let start = new Date(this.rangeDateSelect.start);
                    let end = new Date(this.rangeDateSelect.end);
                    let timeDiff = Math.abs(end.getTime() - start.getTime());
                    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                    return diffDays
                }
                return 0
            },

            chartData() {
                let labels_data = [];
                let datasets_data = [];
                if(this.results && this.results.length) {
                    this.results.forEach(item => {
                        let label = this.dateDifference > 7 ? this.getDateMonth(item.actionDate) : item.weekDay
                        labels_data.push(label);
                        datasets_data.push(item.numberOfAssets)
                    })
                }
                let datasets = [
                    {
                        label: '',
                        backgroundColor: '#2A54CD',
                        data: datasets_data,
                    }
                ];
                return {
                    labels: labels_data,
                    datasets: datasets
                }
            }
        },

        watch: {
            filter() {
                this.init();
            }
        },

        mounted() {
            this.init();
            this.getResponsibleOptions();
        },

        methods: {
            init() {
                if(this.filter.dateFrom && this.filter.dateTo) {
                    this.loading = true;
                    let url = '/water/getReport';
                    this.axios.get(url, {params: this.filter})
                        .then(response => {
                            if(response) {
                                this.count = 0;
                                this.loading = false;
                                this.results = response.data.reverse();
                                this.results.forEach(item => {
                                    this.count = Number(this.count) + Number(item.numberOfAssets);
                                })
                            }
                        })
                        .catch((error => {
                            if(error) this.loading = false;
                        }))
                }
            },

            getResponsibleOptions() {
                let params = {
                    areaID: localStorage.getItem('areaid')
                };
                let url = '/getUsers';
                this.axios.get(url, {params: params})
                    .then(response => {
                        if (response) {
                            this.responsibleOptions = response.data.map(el => {
                                return {
                                    name: el.userName,
                                    userId: el.userID
                                }
                            });
                        }
                    })
            },

            selectChange(e) {
                if (e.Code == 0) {
                    this.date_picker = true;
                }
                else this.clearRangeDate();
            },

            setRangeDate() {
                this.rangeDateSelect = {
                    start: this.rangeDate.start,
                    end: this.rangeDate.end,
                };
                this.date_picker = false;
            },

            clearRangeDate(from_date) {
                if(from_date) this.filterData.days = 6;
                this.rangeDate = {
                    start: null,
                    end: null,
                };
                this.rangeDateSelect = {
                    start: null,
                    end: null,
                };
                this.date_picker = false;
            },

            monthDayFormat(date) {
                if(date) return moment(date).format('MMM DD')
            },

            exportData() {
                if(this.filter.dateFrom && this.filter.dateTo) {
                    // if (this.filterData.responsiblePie) {
                    //     params.UserID = this.filterData.responsiblePie;
                    // }

                    this.axios.post(`/water/exportReport`, this.filter, {responseType: 'blob'})
                        .then(response => {
                            if (response) {
                                let url = URL.createObjectURL(new Blob([response.data], {
                                    type: 'application/excel'
                                }));
                                let link = document.createElement('a');
                                link.style.position = 'absolute';
                                link.style.left = '-9999px';
                                link.href = url;
                                link.setAttribute('download', 'UserReport.xls');
                                document.body.appendChild(link);
                                link.click();
                            }
                        })
                        .catch(error => {
                            if (error) {
                                console.log(error);
                            }
                        })
                }
            },

            getDateMonth(date) {
                let data = date.split('/');
                return `${data[1]}.${data[0]}`
            }
        }
    }
</script>

<style lang="sass" scoped>
    .content-page
        overflow-y: scroll
</style>
