import { required, email, minLength, numeric } from '@vuelidate/validators'

let reqRule = {
    required
};

let emailRule = {
    email
};

let numRule = {
    numeric
};

let password = {
    required,
    minLength: minLength(8)
};

export {
    reqRule, emailRule, password, numRule
}
