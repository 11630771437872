<template>
    <div class="input-element" :class="{'input-element-solo': solo, 'input-element-no-solo': !solo, 'input--error': error, 'input-disabled': disabled}">
        <label :for="id" v-if="label">{{label}}</label>
        <div class="input-container">
            <textarea :id="id"
                   v-model="value"
                   :disabled="disabled"
                   :placeholder="placeholder">
            </textarea>
        </div>
        <div v-if="errorMsg && error" class="error-msg">{{errorMsg}}</div>
    </div>
</template>

<script>
    export default {
        props: {
            modelValue: {
                type: String,
                default: null
            },
            id: {
                default: 1
            },
            label: {
                type: String
            },
            placeholder: {
                default: null
            },
            solo: {
                type: Boolean,
                default: true
            },
            error: {
                type: Boolean,
                default: false
            },
            errorMsg: {
                type: String,
                default: null
            },
            icon: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {}
        },

        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        }
    }
</script>