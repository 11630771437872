import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mitt from 'mitt';
const emitter = mitt();

/* Axios */
import VueAxios from 'vue-axios';
import axiosInstance from "./api/axios.js";

/* Swal */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const swalOptions = {
    confirmButtonColor: '#BA1B1B',
    cancelButtonColor: '#BA1B1B',
    padding: '24px',
    color: '#1A1C1E'
};

import MainService from './services/MainService';

// import {gsap} from "gsap";
// import {Draggable} from "gsap/Draggable";
// gsap.registerPlugin(Draggable);
// window.Draggable = Draggable;

/*Components*/
import iLoading from './components/partial/LoadingEl';
import iButton from './components/partial/ButtonEl';
import iInput from './components/partial/InputEl';
import iTextarea from './components/partial/TextareaEl';
import iDropdown from './components/partial/DropdownEl';
import iSelect from './components/partial/SelectEl';
import detailsDialog from './components/dialogs/DetailsDialog';
import detailsForm from './components/asset/FormAsset';
import clusterDialog from './components/dialogs/ClusterDialog';
import searchDialog from './components/dialogs/SearchDialog';
import resultDialog from './components/dialogs/ResultDialog';
import mapWithPoint from './components/partial/MapWithPoint';

const app = createApp(App).use(store).use(router);

/*Directive*/
app.directive('click-outside', {
    mounted(el, binding) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

app.mixin(MainService);
app.use(VueAxios, axiosInstance);
app.use(VueSweetalert2, swalOptions);

app.config.globalProperties.emitter = emitter;

app.component('iLoading', iLoading);
app.component('iButton', iButton);
app.component('iInput', iInput);
app.component('iTextarea', iTextarea);
app.component('iDropdown', iDropdown);
app.component('iSelect', iSelect);
app.component('detailsDialog', detailsDialog);
app.component('detailsForm', detailsForm);
app.component('clusterDialog', clusterDialog);
app.component('searchDialog', searchDialog);
app.component('resultDialog', resultDialog);
app.component('mapWithPoint', mapWithPoint);

app.mount('#app');
