<template>
    <div class="dialog-side right" id="dialog-cluster">
        <div class="dialog-card">
            <div class="dialog-header" ref="header">
                <div>
                    <div class="card-drag" id="drag-cluster">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                </div>

                <i-button @click="closeDialog()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-body">
                <div class="dialog-title mb-12">
                    <span class="fs-28">Cluster</span>
                </div>

                <div class="dialog-content dialog-content-scroll">
                    <div class="result-items">
                        <div v-for="item in items" :key="item.deviceID" class="result-item" :class="{'active': active_device_id == item.deviceID}" @click="setActiveDevice(item)">
                            <img src="/svg/assets/212_single.svg" alt="">
                            <span class="font-600 fs-14">
                                {{ item.deviceName }}
                            </span>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="loading" v-if="loading"><i-loading></i-loading></div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            items: {
                default: null,
                type: Object
            }
        },
        data() {
            return {
                loading: false,

                item_default: {},
                active_device_id: null
            }
        },

        watch: {
            item() {
                for (let i in this.item) {
                    this.item_default[i] = this.item[i];
                }
            }
        },

        mounted() {
            for (let i in this.item) {
                this.item_default[i] = this.item[i];
            }

            Draggable.create("#dialog-cluster", {
                trigger: "#drag-cluster",
                bounds: ".main-page",
                edgeResistance: 0.65,
                inertia: true,
                autoScroll: 1,
            });
        },

        methods: {
            setActiveDevice(item) {
                this.active_device_id = item.deviceID;
                this.$emit('set_active_device', item.deviceID, 'cluster');
            },
            setLoading(value) {
                this.loading = value;
            },
            closeDialog() {
                this.$emit('close');
            }
        }
    }
</script>
