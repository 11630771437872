<template>
    <div class="dialog medium">
        <div class="dialog-card" id="dialog-details-activities">
            <div class="dialog-header" ref="header">
                <div>
                    <div class="card-drag" id="drag-details-activities">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                                  fill="#909194"/>
                        </svg>
                    </div>
                </div>

                <i-button @click="cancelData()" icon img="/svg/close.svg"></i-button>
            </div>
            <div class="dialog-body">
                <div class="dialog-title mb-12">
                    <span class="fs-28">Details</span>
                </div>

                <div class="dialog-content dialog-content-scroll">
                    <details-form map ref="form" :item="item" :disabled="false" image @loading="setLoading"></details-form>
                </div>


                <div class="dialog-footer">
                    <div>
                        <div v-if="form_error" class="error-color fs-12">The information is not saved. Please check the data.</div>
                    </div>
                    <div class="dialog-footer--items">
                        <i-button @click="cancelData()" color="secondary" small :title="'Cancel'"></i-button>
                        <i-button @click="saveData()" color="primary" small :title="'Save changes'"></i-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading" v-if="loading"><i-loading></i-loading></div>
    </div>
</template>

<script>
    import {gsap} from "gsap";
    import {Draggable} from "gsap/Draggable";

    gsap.registerPlugin(Draggable);

    export default {
        props: {
            item: {
                default: null
            }
        },

        data() {
            return {
                form_error: false,
                loading: false
            }
        },

        mounted() {
            Draggable.create("#dialog-details-activities", {
                trigger: "#drag-details-activities",
                bounds: ".content-page",
                edgeResistance: 0.65,
                inertia: true,
                autoScroll: 1,
            });
        },

        methods: {
            cancelData() {
                this.$emit('cancel_data')
            },
            setLoading(value) {
                this.loading = value;
            },

            saveData() {
                this.form_error = false;
                this.$refs.form.validateData()
                    .then(response => {
                        if(response) {
                            this.setLoading(true);
                            let data = {
                                ID: this.$refs.form.item_data.ID,
                                address: this.$refs.form.item_data.address,
                                assetName: this.$refs.form.item_data.assetName,
                                barcode: this.$refs.form.item_data.barcode,
                                commType: Number(this.$refs.form.item_data.commType),
                                contractNumber: this.$refs.form.item_data.contractNumber,
                                customerMobileNumber: this.$refs.form.item_data.customerMobileNumber,
                                email: this.$refs.form.item_data.email,
                                firstName: this.$refs.form.item_data.firstName,
                                fittingsType: Number(this.$refs.form.item_data.fittingsType),
                                lastName: this.$refs.form.item_data.lastName,
                                latitude: this.$refs.form.item_data.latitude,
                                longitude: this.$refs.form.item_data.longitude,
                                manufactureID: Number(this.$refs.form.item_data.manufactureID),
                                measureTypeID: Number(this.$refs.form.item_data.measureTypeID),
                                modelID: Number(this.$refs.form.item_data.modelID),
                                pipeSize: Number(this.$refs.form.item_data.pipeSize),
                                pipeType: Number(this.$refs.form.item_data.pipeType),
                                position: Number(this.$refs.form.item_data.position),
                                reading: Number(this.$refs.form.item_data.reading),
                                remarks: this.$refs.form.item_data.remarks,
                                sizeLimitation: Number(this.$refs.form.item_data.sizeLimitation),
                                standNo: this.$refs.form.item_data.standNo,
                                stopValve: this.$refs.form.item_data.stopValve,
                                valveAfterMeter: this.$refs.form.item_data.valveAfterMeter,
                                valveBeforeMeter: this.$refs.form.item_data.valveBeforeMeter
                            };
                            let url = '/water/servicePoint/update';
                            this.axios.put(url, data)
                                .then(response_data => {
                                    if(response_data) {
                                        this.setLoading(false);
                                        this.cancelData();
                                    }
                                })
                                .catch((error) => {
                                    if(error) {
                                        this.showErrorMap(error.response.data.message[0]);
                                        this.cancelData();
                                    }
                                })
                        }
                        else this.form_error = true;
                    })

            },
        }
    }
</script>

<style lang="sass" scoped>
    .dialog.medium
        .dialog-card
            height: calc(100vh - 168px)
</style>
