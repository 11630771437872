<template>
    <div v-if="pictures" class="swiper--container" ref="swiper">
        <swiper :slides-per-view="'auto'"
                :space-between="16"
                :modules="modules"
                navigation>
            <swiper-slide v-for="picture in pictures" :key="picture.ID" @click="setActiveImage(picture.photo)" @contextmenu.prevent="open($event, picture)">
               <div class="image-container">
                   <img :src="'data:image/png;base64,'+picture.photo" alt="">
               </div>
            </swiper-slide>
        </swiper>

        <div v-show="isVisibleContext"
             tabindex="-1"
             v-click-outside="close"
             @contextmenu.capture.prevent
             class="context-menu"
             ref="popper">
            <div class="context-menu-item" @click="removeImage()">Delete photo</div>
        </div>
    </div>
</template>

<script>
    import { Navigation } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import Popper from 'popper.js';
    import 'swiper/css';
    export default {
        components: {
            Swiper,
            SwiperSlide
        },

        props: {
            pictures: {
                default: null,
            },
            boundariesElement: {
                type: String,
                default: 'body',
            },
            disabled: {
                default: true
            }
        },
        data() {
            return {
                modules: [Navigation],
                active_image: null,

                context_opened: false,
                contextData: null
            }
        },

        computed: {
            isVisibleContext() {
                return this.context_opened;
            },
        },

        watch: {
            active_image() {
                this.emitter.emit("setActiveImage", this.active_image);
            }
        },

        methods: {
            setActiveImage(image) {
                this.active_image = image
            },

            open(evt, contextData) {
                if(!this.disabled) {
                    this.context_opened = true;
                    this.contextData = contextData;

                    if (this.popper) {
                        this.popper.destroy();
                    }

                    this.popper = new Popper(this.referenceObject(evt), this.$refs.popper, {
                        // strategy: 'fixed',
                        placement: 'left',
                        modifiers: [
                            {
                                name: 'computeStyles',
                                options: {
                                    adaptive: false, // true by default
                                },
                            },
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 4],
                                },
                            },
                            {
                                name: 'flip',
                                options: {
                                    // fallbackPlacements: ['bottom-start'],
                                    rootBoundary: '.swiper--container',
                                    // altBoundary: true
                                },
                            },
                        ]
                    });

                    // Recalculate position
                    this.$nextTick(() => {
                        this.popper.scheduleUpdate();
                    });
                }
            },
            close() {
                this.context_opened = false;
                this.contextData = null;
            },
            referenceObject(evt) {
                let swiper_left = this.$refs.swiper.getBoundingClientRect().left;
                let left = evt.clientX - swiper_left;
                const top = evt.layerY;
                const right = left + 1;
                const bottom = top + 1;
                const clientWidth = 1;
                const clientHeight = 1;

                function getBoundingClientRect() {
                    return {
                        left,
                        top,
                        right,
                        bottom,
                    };
                }

                return {
                    getBoundingClientRect,
                    clientWidth,
                    clientHeight,
                };
            },
            removeImage() {
                let id = this.contextData.ID;
                this.$swal.fire({
                    icon: false,
                    title: 'Delete photo',
                    text: 'Are you sure you want to delete the photo? You cannot undo this action.',
                    showConfirmButton: true,
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: 'Yes, delete'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('loading', true);
                        let url = `/water/removePicture/${id}`;
                        this.axios.delete(url)
                            .then(() => {
                                this.$emit('update', id);

                            })
                            .catch(() => {
                                this.$emit('loading', false);
                            })
                    }
                })
            }
        },
        beforeUnmount() {
            if (this.popper !== undefined) {
                this.popper.destroy();
            }
        },
    }
</script>

<style lang="sass">
    .swiper--container
        position: relative
    .swiper
        position: relative
        margin-bottom: 12px
        .swiper-slide
            width: 150px
        .image-container
            position: relative
            overflow: hidden
            width: 150px
            height: 150px
            border-radius: 8px
            transition: 0.3s ease-in-out
            user-select: none
            &:hover
                cursor: pointer
                opacity: 0.6
            img
                position: absolute
                left: 0
                top: 0
                right: 0
                object-fit: cover
                object-position: center center
                width: 100%
                height: 100%
                user-select: none
                display: block

    .swiper-button-prev, .swiper-button-next
        position: absolute
        top: 50%
        transform: translateY(-50%)
        z-index: 3
        width: 40px
        height: 40px
        background: #FFFFFF
        border-radius: 50%
        cursor: pointer
        transition: 0.3s ease-in-out
        &:hover
            background-color: var(--color-primary-hover-light)
        &::before
            content: ''
            position: absolute
            left: 0
            top: 0
            right: 0
            bottom: 0
            background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5238 12.3532L13.8762 12L13.5238 11.6468L9.29657 7.41039L9.99984 6.70711L15.2927 12L9.99984 17.2929L9.29657 16.5896L13.5238 12.3532Z" fill="blue" stroke="blue"/> </svg>')
            background-repeat: no-repeat
            background-position: center center
    .swiper-button-prev
        left: 16px
        &::before
            transform: rotate(180deg)

    .swiper-button-next
        right: 16px

    .swiper-button-disabled
        display: none
</style>
