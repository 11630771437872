window.axios = require('axios');
let token = localStorage.getItem('token');
let axiosInstance = window.axios.create({
    // baseURL: '/api/',
    baseURL: process.env.NODE_ENV === 'development' ? '/api/' : 'https://4438.mdmsdemo.com/api/',
    headers: {
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Requested-With, content-type',
        'Authorization': 'Bearer ' + token,
    },
});


// Предварительная обработка ответов
axiosInstance.interceptors.response.use(response=>response, function(error) {
    if (error.response.status == 401 && localStorage.getItem('token')) {
        localStorage.removeItem('token');
        window.location.pathname = "/login";
        return error;
    }
    else {
        return Promise.reject(error);
    }
});

export default axiosInstance;
