// window.axios = require('axios');
export default {
    actions: {
        async fetchDropdown(ctx, axios) {
            let url = '/water/getSettings';
            axios.get(url).then(response => {
                if (response.data) {
                    let dropdown = response.data;

                    let url = '/water/getManufactureModel';
                    axios.get(url).then(response_model => {
                        if (response.data) {
                            ctx.commit('setDropdown', dropdown);
                            ctx.commit('setManufactureModel', response_model.data);
                        }
                    });
                }
            });
        }
    },

    mutations: {
        setDropdown(state, data) {
            // let map = data.reduce((acc, cur)=>{
            //     acc[cur.ParameterName] = acc[cur.ParameterName] || [];
            //     acc[cur.ParameterName].push(cur);
            //     return acc;
            // },{});
            //
            // state.dropdown = map;

            state.dropdown = data
        },

        setManufactureModel(state, data) {
            state.dropdown = Object.assign(state.dropdown, data);
        }

    },

    state: {
        dropdown: undefined,
    },

    getters: {
        getDropdown(state) {
            return state.dropdown
        }
    },
}
