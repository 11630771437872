<template>
    <div class="app">
        <nav-app v-if="user" @show_search_dialog="showSearchDialog"></nav-app>
        <router-view></router-view>

        <search-dialog v-if="searchDialog" @close_dialog="closeSearchDialog()"></search-dialog>

        <!-- Large image -->
        <div class="date_picker_overlay" v-if="active_image" @click="hiddenActiveImage()">
            <div class="image-container">
                <div class="close" @click="hiddenActiveImage()"><img src="/svg/close.svg" alt=""></div>
                <img :src="'data:image/png;base64,'+active_image" alt="" class="image">
            </div>
        </div>
    </div>

</template>

<script>
    import '/src/assets/sass/app.sass';
    import NavApp from './components/partial/NavigationApp';

    export default {
        name: 'App',

        components: {
            NavApp
        },

        data() {
            return {
                searchDialog: false,
                active_image: false,

                resultsDialog: false,
                activeResults: null
            }
        },

        computed: {
            user() {
                return this.$store.getters.getUser;
            }
        },

        mounted() {
            this.$store.dispatch('fetchUser');
            this.emitter.on("setActiveImage", e => {
                this.active_image = e ? e : null;
            });
        },

        methods: {
            showSearchDialog() {
                this.searchDialog = true;
            },
            closeSearchDialog() {
                this.searchDialog = false;
            },
            hiddenActiveImage() {
                this.emitter.emit("setActiveImage", null);
            }
        }

    }
</script>

<style lang="sass" scoped>
    .date_picker_overlay
        z-index: 10000
        .image-container
            max-height: calc(100vh - 152px)
            max-width: calc(100vw - 285px)
            overflow: hidden
            border-radius: 12px
            position: relative
            display: flex
            .close
                position: absolute
                z-index: 2
                right: 16px
                top: 16px
                width: 40px
                height: 40px
                background-color: #ffffff
                display: flex
                align-items: center
                justify-content: center
                cursor: pointer
                border-radius: 50%
                transition: 0.3s ease-in-out
                &:hover
                    background-color: var(--color-primary-hover-light)
            img.image
                height: 100%
                width: 100%
                max-height: calc(100vh - 152px)
                max-width: calc(100vw - 285px)
</style>
