<template>
    <div>
        <div v-if="step === 'sign_in'">
            <h1 class="mb-6">Sign in</h1>
            <p class="mb-8 text-gray-dark">Enter your details to proceed further</p>

            <div class="mb-6">
                <i-input v-model="username"
                         id="name"
                         :solo="false"
                         :label="'Username'"
                         :error="v$.username.$error"
                         :errorMsg="'This field is required'"
                         icon="username"
                         :placeholder="'Start typing...'">
                </i-input>
            </div>

            <div class="mb-1">
                <i-input v-model="password"
                         id="password"
                         :solo="false"
                         type="password"
                         :error="v$.password.$error || serverError"
                         :errorMsg="password_error"
                         icon="password"
                         :label="'Password'"
                         :placeholder="'Start typing...'"
                ></i-input>
            </div>
            <div class="text-end mb-11">
                <i-button text middle to="/recover" :title="'Recover password'"></i-button>
            </div>

            <i-button @click="signIn" :loading="loading" block :title="'Confirm'"></i-button>
        </div>
        <div v-if="step === 'set_pass'">
            <set-new-pass :token="token" :userId="userId"  :current_password="password"></set-new-pass>
        </div>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {reqRule} from "./../../services/Validation";
    import SetNewPass from './SetNewPass';

    export default {
        components: {
            SetNewPass
        },
        data() {
            return {
                url: 'login',
                step: 'sign_in',

                loading: false,

                username: null,
                password: null,

                serverError: null,
                userId: null,
                token: null,

                v$: useVuelidate(),
            }
        },

        computed: {
            password_error() {
                if(this.serverError) return this.serverError;
                else return 'This field is required';
            }
        },

        methods: {
            signIn() {
                this.serverError = null;
                this.v$.$touch();
                if (this.v$.$invalid) {
                    return;
                } else {
                    this.loading = true;

                    let data = {
                        username: this.username,
                        password: this.password
                    };

                    this.axios.post(this.url, data)
                        .then(response => {
                            if (response && response.data.status && response.data.status == 401) {
                                this.serverError = response.data.message;
                                this.loading = false;
                            }
                            if(response.data.token && !response.data.expired) {
                                this.token = response.data.token;
                                localStorage.setItem('token', this.token);
                                localStorage.setItem('areaid', response.data.areaid);
                                localStorage.setItem('country', response.data.country);
                                localStorage.setItem('city', response.data.city);
                                localStorage.setItem('user_id', response.data.id);
                                window.location.pathname = "/";
                            }
                            if(response.data.token && response.data.expired) {
                                this.token = response.data.token;
                                this.userId = response.data.id;
                                localStorage.setItem('areaid', response.data.areaid);
                                localStorage.setItem('country', response.data.country);
                                localStorage.setItem('city', response.data.city);
                                this.step = 'set_pass'

                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                        .catch(error => {
                            if (error) {
                                this.serverError = error.response.data.message;
                            }
                        })
                }
            }
        },

        validations() {
            return {
                username: reqRule,
                password: reqRule,
            }
        }
    }
</script>
