<template>
    <div id="content" class="info-page content-page">
        <div class="container">

            <div class="info-page-card">
               <div class="info-page-card--content">
                   <div class="mb-6 text-center">
                       <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M33 45H39V51H33V45ZM33 21H39V39H33V21ZM35.97 6C19.41 6 6 19.44 6 36C6 52.56 19.41 66 35.97 66C52.56 66 66 52.56 66 36C66 19.44 52.56 6 35.97 6ZM36 60C22.74 60 12 49.26 12 36C12 22.74 22.74 12 36 12C49.26 12 60 22.74 60 36C60 49.26 49.26 60 36 60Z" fill="#2A54CD"/>
                       </svg>
                   </div>
                   <h1 class="fs-45 font-400 primary mb-6">Page not found</h1>
                   <p class="fs-14 mb-8">The URL you requested wasn’t found on the server. Please check the address or try going back to the previous page</p>
                   <i-button to="/" title="Go to homepage" block></i-button>
               </div>
            </div>
        </div>
    </div>
</template>
