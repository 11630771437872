<template>
    <div v-if="user" id="content" class="main-page content-page">
        <div id="loading_items" v-if="loading_items && !loading">
            <i-loading></i-loading>
        </div>
        <div class="dialog center" v-if="loading">
            <div class="dialog-card">
                <div class="dialog-body">
                    <div class="dialog-header">Loading elements...</div>
                    <i-loading></i-loading>
                </div>
            </div>

        </div>

        <map-loader ref="map"
                    :mapConfig="mapOptions"
                    :mapData="markers"
                    :activeItem="activeMarker"
                    @set_active_marker="setActiveMarker"
                    @set_active_cluster="setActiveCluster"
                    @map_dragend="setMapOptions">
        </map-loader>

        <details-dialog :item="activeMarker"
                        :position="detailsDialogPosition"
                        ref="itemDetails"
                        @remove_device="removeDevice"
                        @update_device="updateDevice"
                        @close="closeDetailsDialog" v-if="detailsDialog"></details-dialog>

        <cluster-dialog :items="activeCluster"
                        ref="clusterDialog"
                        @close="closeClusterDetailsDialog"
                        @set_active_device="setActiveMarker"
                        v-if="clustersDialog">
        </cluster-dialog>

        <result-dialog :items="activeResults"
                       v-if="resultsDialog"
                       ref="results"
                       @set_active_device="setActiveMarker"
                       @close="closeResultDialog()">
        </result-dialog>

        <!--        <info-summary :style="infoSummaryStyle"></info-summary>-->
    </div>
</template>

<script>
    import assets from './../assets/json/assets'

    import MapLoader from "./../components/map/GoogleMapLoader";
    // import InfoSummary from "./InfoSummary";

    export default {
        components: {
            MapLoader,
        },
        data() {
            return {
                loading: false,
                loading_items: false,
                search_loading: false,
                first_loading: true,

                timer: false,
                timerId: null,

                mapOptions: {
                    latitude1: 31.903491,
                    latitude2: 31.906278,
                    longitude1: 34.991903,
                    longitude2: 34.995337,
                    scale: 19,
                    "_typesOfAssets": "",
                },
                markers: [],

                activeAsset:  null, /*map - cluster - results*/

                activeMarker: null,
                detailsDialog: false,

                activeCluster: null,
                clustersDialog: false,

                activeResults: null,
                resultsDialog: false
            }
        },

        computed: {
            assets() {
                return assets.assets;
            },
            user() {
                return this.$store.getters.getUser;
            },
            detailsDialogPosition() {
                let position = {
                    right: 'auto',
                    left: 0,
                };
                if(this.resultsDialog) position.left = '349px';
                return position
            }
        },

        mounted() {
            this.emitter.on("searchResultsData", e => {
                if(this.activeAsset === 'map') this.closeDetailsDialog();
                this.activeAsset = 'results';
                this.resultsDialog = true;
                this.activeResults = e ? e : null;
            });
        },

        methods: {
            /*Load devices from current coordinates and zoom*/
            init() {
                if (!this.loading_items) {
                    this.loading = true;
                    this.loading_items = true;
                    let url = 'water/getAllDevicesByGIS';
                    this.axios.post(url, this.mapOptions)
                        .then(response => {
                            if (response) {
                                // if (response.data.status) this.showErrorMap(response.data.message);
                                this.markers = response.data;
                                this.loading = false;
                                this.loading_items = false;
                                this.first_loading = false;
                                this.timer = false;
                            }
                        })
                        .catch((error) => {
                            if (error) this.showErrorMap(error.message);
                        })
                }
            },
            /*Load data after map drag*/
            setMapOptions(top, bottom, zoom) {
                this.mapOptions.longitude1 = Number(top.lng().toFixed(6));
                this.mapOptions.longitude2 = Number(bottom.lng().toFixed(6));
                this.mapOptions.latitude1 = Number(top.lat().toFixed(6));
                this.mapOptions.latitude2 = Number(bottom.lat().toFixed(6));
                this.mapOptions.scale = zoom;
                if(this.first_loading && !this.timer) {
                    this.timer = true;
                    this.timerId = setTimeout(this.init, 5000);
                }
                if(!this.first_loading && !this.timer) this.init();
            },

            /*set active device*/
            setActiveMarker(id, asset) {
                let url = `water/getInfoDevice/${id}`;
                this.axios.get(url)
                    .then(response => {
                        if (response) {
                            this.activeAsset = asset;
                            this.activeMarker = response.data;
                            this.detailsDialog = true;
                            this.$refs.map.loading = false;
                        }
                    })
                    .catch((error) => {
                        if (error) this.showErrorMap(error.message);
                    })
            },

            /*set active cluster*/
            setActiveCluster(id) {
                let url = `/water/getListOfGroupAssets?deviceID=${id}`;
                this.axios.get(url)
                    .then(response => {
                        if (response) {
                            this.activeAsset = 'cluster';
                            this.activeCluster = response.data;
                            this.clustersDialog = true;
                            this.$refs.map.loading = false;
                        }
                    })
                    .catch((error) => {
                        if (error) this.showErrorMap(error.message);
                    })
            },

            closeDetailsDialog() {
                if(this.activeAsset === 'results') this.$refs.results.active_device_id = null;
                if(this.activeAsset === 'cluster') this.$refs.clusterDialog.active_device_id = null;
                this.activeMarker = null;
                this.detailsDialog = false;
                this.activeAsset = null;
                this.$refs.map.resetActiveMarker();
            },

            closeClusterDetailsDialog() {
                this.activeCluster = null;
                this.clustersDialog = false;
                this.activeAsset = null;
                this.$refs.map.resetActiveCluster();
            },

            closeResultDialog() {
                this.resultsDialog = false;
                this.activeResults = null;
                this.activeAsset = null;
            },

            showErrorMap(error) {
                this.mainNotification(error);
            },

            /*update data after edit device info*/
            updateDevice(item) {
                if(this.activeAsset === 'results') {
                    let index = this.activeResults.findIndex(item => item.deviceID == this.activeMarker.ID);
                    if(index > -1) this.activeResults[index].barcode = item.barcode;
                }
            },

            /*remove device and update map*/
            removeDevice() {
                /*if device opened from map*/
                if(this.activeAsset === 'map') {
                    this.$refs.map.selectMarker = null;
                    this.$refs.map.selectMarkerIndex = null;
                    this.$refs.map.reset();
                    this.markers = [];
                    this.init();
                }
                /*if device opened from search results*/
                if(this.activeAsset === 'results') {
                    let index = this.activeResults.findIndex(item => item.deviceID == this.activeMarker.ID);
                    if(index > -1) this.activeResults.splice(index, 1);
                    this.$refs.results.active_device_id = null;
                }
            }
        },

        unmounted() {
            if(this.timerId) clearTimeout(this.timerId);
        }
    }
</script>

<style scoped lang="sass">
    #loading_items
        position: fixed
        left: 20px
        bottom: 20px
        z-index: 10
</style>
